import {Pipe, PipeTransform} from '@angular/core';
import {FilterPipe} from '../../../../../@fuse/pipes/filter.pipe';

@Pipe({
  name: 'search'
})
export class SearchPipe implements PipeTransform {

  transform(value: any[], searchText: string): any {
    const filtered = new FilterPipe().transform(value, searchText, searchText);
    return (filtered.length === 0) ? undefined : filtered;
  }

}
