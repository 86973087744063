import {Measurement} from './measurement';

export class DrugInput {

  drugName: string;

  amount = 0;

  measurement = Measurement.D;


  constructor(drugName: string = '', amount: number = 0, measurement: Measurement = Measurement.D) {
    this.drugName = drugName;
    this.amount = amount;
    this.measurement = measurement;
  }
}
