import {NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {RouterModule, Routes} from '@angular/router';
import {TranslateModule} from '@ngx-translate/core';
import 'hammerjs';

import {FuseModule} from '@fuse/fuse.module';
import {FuseSharedModule} from '@fuse/shared.module';

import {fuseConfig} from './fuse-config';

import {AppComponent} from './app.component';
import {FuseMainModule} from './main/main.module';
import {BindOnceDirective} from './directives/bind-once.directive';
import {PlayerDetailsComponent} from './main/content/bags/player-details/player-details.component';
import {SidebarJSModule} from 'ng-sidebarjs';
import {environment} from '../environments/environment';
import {AngularFireModule} from 'angularfire2';
import {AngularFirestoreModule} from 'angularfire2/firestore';
import {AngularFireAuthModule} from 'angularfire2/auth';
import {AngularFireStorageModule} from 'angularfire2/storage';
import {OnchxModule} from './main/content/onchx/onchx.module';

const appRoutes: Routes = [
  {
    path: '**',
    redirectTo: 'onchx'
  }
];

@NgModule({
  declarations: [
    AppComponent,
    BindOnceDirective,
    PlayerDetailsComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule.forRoot(appRoutes),
    TranslateModule.forRoot(),
    SidebarJSModule.forRoot(),

    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule, // imports firebase/firestore, only needed for database features
    AngularFireAuthModule, // imports firebase/auth, only needed for auth features,
    AngularFireStorageModule, // imports firebase/storage only needed for storage features

    // Fuse Main and Shared modules
    FuseModule.forRoot(fuseConfig),
    FuseSharedModule,
    FuseMainModule,
    OnchxModule,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {
}

declare global {
  interface Array<T> {
    nextId(): number;
  }

  interface Number {
    notNaN(): number;
  }
}

Number.prototype.notNaN = function (): number {
  return Number.isNaN(this) ? 0 : this;
};

Array.prototype.nextId = function (): number {
  let id = 0;
  for (let i = 0; i < this.length; i++) {
    if (this[i] && this[i].id) {
      if (this[i].id > id) {
        id = this[i].id;
      }
    }
  }
  return id + 1;
};


