import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'app-drug-list-item',
  templateUrl: './drug-list-item.component.html',
  styleUrls: ['./drug-list-item.component.scss']
})
export class DrugListItemComponent implements OnInit {

  @Input() item: any;

  constructor() {
  }

  ngOnInit() {
  }

}
