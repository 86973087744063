export abstract class BaseModel {

  abstract nameInstance: string;

  apply(callback: (any) => void): any {
    callback(this);
    return this;
  }

  run<T>(callback: (any) => T): T {
    return callback(this);
  }
}
