/*
export const navigation = [
  {
    'id': 'onchx',
    'title': 'OncHx',
    'type': 'group',
    'children': [
      {
        'id': 'home',
        'title': 'Home',
        'type': 'item',
        'icon': 'home',
        'url': '/home'
      },
      {
        'id': 'input',
        'title': 'Add Treatment',
        'type': 'item',
        'icon': 'plus',
        'url': '/input'
      }
    ]
  }
];
*/
export const navigation = [
  {
    'id': 'home',
    'title': 'Home',
    'type': 'item',
    'icon': 'home',
    'url': '/home'
  },
  {
    'id': 'input',
    'title': 'Add Treatment',
    'type': 'item',
    'icon': 'plus',
    'url': '/input'
  },
  {
    'id': 'profile',
    'title': 'Profile',
    'type': 'item',
    'icon': 'plus',
    'url': '/profile'
  }
];
