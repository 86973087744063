import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs';
import {UserService} from './user.service';
import {AngularFireAuth} from 'angularfire2/auth';
import {map, take} from 'rxjs/operators';

@Injectable()
export class AuthGuard implements CanActivate {

  constructor(public auth: UserService, public userFire: AngularFireAuth, public router: Router) {

  }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    return this.userFire.authState.pipe(
      take(1),
      map((authState) => {
        if (!!authState) {
          return true;
        }
        this.userFire.auth.signInAnonymously().then(value => {
          this.router.navigateByUrl(this.router.url);
        }, value => {
          this.router.navigate(['/register']);

        });
      })
    );

  }
}
