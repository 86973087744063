import {User} from './user';
import {deserialize, deserializeAs, serialize, serializeAs} from 'cerialize';
import {BaseModel} from './BaseModel';

export class Team extends BaseModel {

  static uniqueName = 'Team';
  nameInstance = Team.uniqueName;

  @serialize
  @deserialize
  name = '';

  @serializeAs(User)
  @deserializeAs(User)
  players: User[] = [];


  constructor(name: string, ...user: User[]) {
    super();
    this.players.push(...user);
    this.name = name;
  }
}
