import {Injectable} from '@angular/core';
import {User} from '../models/user';
import {Team} from '../models/team';

@Injectable()
export class UsersService {

  users: User[] = [];

  lastTeams: Team[] = [];

  constructor() {
    const usersNew = localStorage.getArray(User.uniqueName, User);
    if (usersNew) {
      this.users = usersNew;
    } else {
      this.users.push(new User('Player One', 1),
        new User('Player Two', 2),
        new User('Player Three', 3),
        new User('Player Four', 4));
      localStorage.saveArray(this.users);
    }

    this.lastTeams = localStorage.getArray(Team.uniqueName, Team);

    if (!this.lastTeams) {
      this.lastTeams = [];
      this.lastTeams.push(new Team(
        'Team One', this.users[0], this.users[1]
      ), new Team(
        'Team Two', this.users[2], this.users[3]
      ));
      localStorage.saveArray(this.lastTeams);

    }
  }

  updateTeams() {
    localStorage.saveArray(this.lastTeams);
  }

  newUser(name: string) {
    const user = new User(name);
    user.id = this.users.nextId();
    this.users.push(user);
    localStorage.saveArray(this.users);
    return user;
  }

  getUsers(name: string) {
    if (!name || name === '') {
      return this.users;
    }
    return this.users.filter(value => {
      value.name.startsWith(name);
    });
  }

  nextValidUserId() {
    let lastId = 0;
    this.users.forEach(value => {
      (value.id > lastId).ifTrue(() => {
        lastId = value.id;
      });
    });
    return lastId + 1;
  }
}
