import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DrugInput} from '../model/drug-input';
import {Measurement} from '../model/measurement';
import {MatDialog} from '@angular/material';
import {StaticContextService} from '../service/static-context.service';
import {InputType} from '../component/custom-input/custom-input.component';
import {Router} from '@angular/router';

@Component({
  selector: 'app-drug-input',
  templateUrl: './drug-input.component.html',
  styleUrls: ['./drug-input.component.scss']
})
export class DrugInputComponent implements OnInit {

  form: FormGroup;

  testData: any;

  measurements = Object.keys(Measurement);
  isDisabled = true;


  constructor(private formBuilder: FormBuilder, private router: Router, private dialog: MatDialog, private staticContext: StaticContextService) {
    if (!this.staticContext.drugInput) {
      this.staticContext.drugInput = new DrugInput();
    }
  }

  drugInput() {
    return this.staticContext.drugInput;
  }

  plusAmount() {
    this.staticContext.drugInput.amount += 1;
  }

  minusAmount() {
    if (this.staticContext.drugInput.amount === 0) {
      return;
    }
    this.staticContext.drugInput.amount -= 1;
  }


  ngOnInit() {

    this.testData = [
      {
        controlName: 'drugName',
        default: this.staticContext.drugInput.drugName,
        title: 'Drug Name',
        type: InputType.BUTTON,
        placeholder: 'Name of Drug',
        buttonClick: () => {
          this.router.navigate(['/drugList']);
        }
      },
      {
        controlName: 'amount',
        default: this.staticContext.drugInput.amount,
        title: 'Amount',
        type: InputType.INCREMENT,
        classWrapper: 'dfg'
      }, {
        controlName: 'measurement',
        default: this.staticContext.drugInput.measurement,
        validators: Validators.required,
        title: 'Measurement',
        placeholder: 'Measurement',
        type: InputType.SELECT,
        multipleList: Object.values(Measurement),
        listText: (item) => {
          return item;
        },
        classWrapper: 'dfg'
      },
      {
        controlName: 'notes',
        default: '',
        title: 'Notes',
        placeholder: 'Leave a note',
        type: InputType.TEXT_AREA
      }
    ];

    const controlConfig = {};

    this.testData.forEach(value => {
      controlConfig[value.controlName] = [value.default, value.validators];
    });


    this.form = this.formBuilder.group(controlConfig);

  }
}
