import {deserialize, serialize} from 'cerialize';
import {BaseModel} from './BaseModel';

export class Points extends BaseModel {

  static uniqueName = 'Points';
  nameInstance = Points.uniqueName;

  @serialize
  @deserialize
  onBoard = 1;

  @serialize
  @deserialize
  offBoard = 1;

  @serialize
  @deserialize
  inHole = 3;

  @serialize
  @deserialize
  holesCancelBoards = true;

  @serialize
  @deserialize
  boardsCancelHoles = true;

  @serialize
  @deserialize
  bagsCancelEachOther = true;

  constructor(onBoard: number = 1, inHole: number = 3) {
    super();
    this.onBoard = onBoard;
    this.offBoard = onBoard;
    this.inHole = inHole;
  }
}
