import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Measurement} from '../model/measurement';
import {MatDialog} from '@angular/material';
import {StaticContextService} from '../service/static-context.service';
import {InputType} from '../component/custom-input/custom-input.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {


  form: FormGroup;

  testData: any;

  measurements = Object.keys(Measurement);
  isDisabled = true;


  constructor(private formBuilder: FormBuilder, private router: Router,
              private dialog: MatDialog, public activatedRoute: ActivatedRoute,
              private staticContext: StaticContextService) {

  }


  ngOnInit() {

    const editable = true;

    this.testData = [
      {
        controlName: 'firstName',
        default: '',
        title: 'First Name',
        editable: editable,
        type: InputType.INPUT,
      },
      {
        controlName: 'lastName',
        default: '',
        title: 'Last Name',
        editable: editable,
        type: InputType.INPUT,
      },
      {
        controlName: 'mrn',
        default: '',
        title: 'Medical Record #',
        editable: editable,
        type: InputType.INPUT,
      },
      {
        controlName: 'oncologistName',
        default: '',
        title: 'Oncologist Name',
        editable: editable,
        type: InputType.INPUT,
      },
      {
        controlName: 'oncologistPhone',
        default: '',
        title: 'Oncologist Phone #',
        editable: editable,
        type: InputType.INPUT,
      },
      {
        controlName: 'triagePhone',
        default: '',
        title: 'Triage Phone #',
        editable: editable,
        type: InputType.INPUT,
      },
      {
        controlName: 'allergies',
        default: [],
        title: 'Allergies',
        placeholder: 'Add Allergy...',
        editable: editable,
        type: InputType.CHIPS,
      },
      {
        controlName: 'currentMeds',
        default: [],
        title: 'Current Medications',
        placeholder: 'Add Medication...',
        editable: editable,
        type: InputType.CHIPS,
      }
    ];

    const controlConfig = {};

    this.testData.forEach(value => {
      controlConfig[value.controlName] = [value.default, value.validators];
    });


    this.form = this.formBuilder.group(controlConfig);

  }

}
