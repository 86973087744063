import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {
  MatAutocompleteModule,
  MatButtonModule,
  MatCardModule,
  MatCheckboxModule,
  MatChipsModule,
  MatDatepickerModule,
  MatDialogModule,
  MatFormFieldModule,
  MatIconModule,
  MatInputModule,
  MatOptionModule,
  MatRadioModule,
  MatRippleModule,
  MatSelectModule,
  MatSlideToggleModule,
  MatTabsModule,
  MatToolbarModule
} from '@angular/material';
import {RouterModule} from '@angular/router';
import {Deserialize, INewable, ISerializable, Serialize} from 'cerialize';
import {FuseSharedModule} from '../../../../@fuse/shared.module';
import {LogonComponent} from './logon/logon.component';
import {RegisterComponent} from './register/register.component';
import {InputComponent} from './input/input.component';
import {VirtualScrollModule} from 'angular2-virtual-scroll';
import {ProfileComponent} from './profile/profile.component';
import {ProfileEditComponent} from './profile-edit/profile-edit.component';
import {WelcomeComponent} from './welcome/welcome.component';
import {TutorialComponent} from './tutorial/tutorial.component';
import {AuthGuard} from './service/auth.guard';
import {HomeComponent} from './home/home.component';
import {RadiationComponent} from './radiation/radiation.component';
import {SurgeryComponent} from './surgery/surgery.component';
import {LabsComponent} from './labs/labs.component';
import {SupportiveStaffComponent} from './supportive-staff/supportive-staff.component';
import {ChemoInputComponent} from './chemo-input/chemo-input.component';
import {RadiationInputComponent} from './radiation-input/radiation-input.component';
import {TestInputComponent} from './test-input/test-input.component';
import {LabInputComponent} from './lab-input/lab-input.component';
import {SurgeryInputComponent} from './surgery-input/surgery-input.component';
import {ScanInputComponent} from './scan-input/scan-input.component';
import {DrugInputComponent} from './drug-input/drug-input.component';
import {DrugListComponent} from './drug-list/drug-list.component';
import {DrugListItemComponent} from './drug-list-item/drug-list-item.component';
import {SearchPipe} from './pipe/search.pipe';
import {MatMomentDateModule} from '@angular/material-moment-adapter';
import {CustomInputComponent} from './component/custom-input/custom-input.component';


const routes = [
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'logon',
    component: LogonComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'input',
    component: InputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'welcome',
    component: WelcomeComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'tutorial',
    component: TutorialComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'drugInput',
    component: DrugInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'labInput',
    component: LabInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'chemoInput',
    component: ChemoInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'testInput',
    component: TestInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'surgeryInput',
    component: SurgeryInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'scanInput',
    component: ScanInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'radiationInput',
    component: RadiationInputComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'profileEdit',
    component: ProfileEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'drugList',
    component: DrugListComponent,
    canActivate: [AuthGuard]
  },
  {
    path: '**',
    redirectTo: 'home',
    canActivate: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forChild(routes),
    CommonModule,
    VirtualScrollModule,
    MatButtonModule,
    MatDatepickerModule,
    MatMomentDateModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatSlideToggleModule,
    MatToolbarModule,
    MatCardModule,
    MatOptionModule,
    MatSelectModule,
    MatTabsModule,
    MatDialogModule,
    MatRadioModule,
    MatRippleModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,


    FuseSharedModule
  ],
  declarations: [
    LogonComponent,
    RegisterComponent,
    RegisterComponent,
    ProfileComponent,
    ProfileEditComponent,
    LogonComponent,
    RegisterComponent,
    InputComponent,
    WelcomeComponent,
    TutorialComponent,
    HomeComponent,
    RadiationComponent,
    SurgeryComponent,
    LabsComponent,
    SupportiveStaffComponent,
    ChemoInputComponent,
    DrugInputComponent,
    RadiationInputComponent,
    SurgeryInputComponent,
    LabInputComponent,
    ScanInputComponent,
    TestInputComponent,
    DrugListComponent,
    DrugListItemComponent,
    SearchPipe,
    CustomInputComponent
  ],
  providers: [
    AuthGuard
    //  GameService,
  ],
  entryComponents: [
    // TeamWinsDialogComponent,
  ]
})
export class OnchxModule {
}

declare global {
  interface Boolean {
    ifTrue(ifTrueCallback: () => void);
  }

  interface Storage {
    saveObject(key: string, object: any);

    saveArray(object: any[]);

    getArray(key: string, type?: Function | INewable<any> | ISerializable): any[];

    getObject(key: string, type?: Function | INewable<any> | ISerializable): any;

  }

  interface Array<T> {
    firstOrNull<T>(callback: (T) => boolean): T;
  }
}

Array.prototype.firstOrNull = function <T>(callback: (T) => boolean): T {
  for (let i = 0; i < this.length; i++) {
    if (callback(this[i])) {
      return this[i];
    }
  }
  return null;
};

Storage.prototype.saveArray = function (object: any[]) {
  const save = Serialize(object);
  if (object.length === 0) {
    return;
  }
  localStorage.setItem(`Array_${object[0].nameInstance}`, JSON.stringify(save));
};

Storage.prototype.getArray = function (key: string, type?: Function | INewable<any> | ISerializable): any[] {
  return Deserialize(JSON.parse(localStorage.getItem(`Array_${key}`)), type);
};


Storage.prototype.saveObject = function (key: string, object: any) {
  const save = Serialize(object);
  if (object.constructor === [].constructor) {
    this.saveArray(object);
  } else {
    localStorage.setItem(key, JSON.stringify(save));
  }
};

Storage.prototype.getObject = function (key: string, type?: Function | INewable<any> | ISerializable): any {
  return Deserialize(JSON.parse(localStorage.getItem(key)), type);
};

Boolean.prototype.ifTrue = function (ifTrueCallback: () => void) {
  if (this) {
    ifTrueCallback();
  }
};

