import {Team} from './team';
import {Round} from './round';
import {deserialize, deserializeAs, serialize, serializeAs} from 'cerialize';
import {GameType} from './gametype';
import {BaseModel} from './BaseModel';

export class Game extends BaseModel {

  static uniqueName = 'Game';
  nameInstance = Game.uniqueName;

  @serialize
  @deserialize
  id: number;

  @serialize
  @deserialize
  gameComplete = false;

  // Two teams only
  @serializeAs(Team)
  @deserializeAs(Team)
  teams: Team[];

  @serializeAs(GameType)
  @deserializeAs(GameType)
  gameType: GameType;

  @serializeAs(Date)
  @deserializeAs(Date)
  dateStarted: Date = new Date();

  @serializeAs(Date)
  @deserializeAs(Date)
  dateEnded: Date = new Date();

  @serializeAs(Round)
  @deserializeAs(Round)
  rounds: Round[] = [];

  constructor(teamOne: Team, teamTwo: Team, gameType: GameType = new GameType()) {
    super();
    this.teams = [teamOne, teamTwo];
    this.gameType = gameType;
  }

  saveGame() {
    this.id = +localStorage.getItem('latestGameNum');
    if (isNaN(this.id)) {
      this.id = 0;
    }
    this.id += 1;

    this.dateEnded = new Date();

    localStorage.setItem('latestGameNum', this.id.toString());

    localStorage.saveObject(`PastGame_${this.id}`, this);
  }

  totalScore(player: number, includeBustForCurrentRound = true, toRound: number = this.rounds.length - 1) {
    let score = 0;
    const curRound = this.currentRound().roundId;
    this.rounds.forEach((value, index) => {
      if (index <= toRound) {
        score += value.scoreForPlayer(this.gameType, player);
        if (this.gameType.gameSettings.bust && score > this.gameType.gameSettings.winningScore) {
          if (includeBustForCurrentRound || curRound !== value.roundId) {
            score = this.gameType.gameSettings.bustScoreTo;
          }
        }
      }
    });
    return score;
  }

  resetCurrentRoundScore() {
    this.currentRound().scores.forEach(value => {
      value.resetScore();
    });
  }

  roundNumber() {
    return this.rounds.length;
  }

  currentPlayer(team = 0) {
    return this.teams[team].players[this.currentPlayerIndex(team)];
  }

  currentPlayerIndex(team = 0) {
    if (!this.currentRound()) {
      return 0;
    }
    const id = this.currentRound().scores[team].userId;
    for (let i = 0; i < this.teams[team].players.length; i++) {
      const value = this.teams[team].players[i];
      if (value.id === id) {
        return i;
      }
    }
    return 0;
  }

  bagsLeftInRound(teamIndex: number, round = this.currentRoundIndex()) {
    const roundItem = this.rounds[round].scores[teamIndex];
    return this.gameType.gameSettings.bagsPerTurn - (roundItem.onBoard + roundItem.inHole);
  }

  nextPlayerIndex(team = 0) {
    const playerNow = this.currentPlayerIndex(team);
    let newIndex = playerNow + 1;
    if (newIndex >= this.teams[team].players.length) {
      newIndex = 0;
    }
    return newIndex;
  }

  newRound(playerOneIndex = this.nextPlayerIndex(0), playerTwoIndex = this.nextPlayerIndex(1)) {
    const round = new Round(this.rounds.length, this.teams[0].players[playerOneIndex], this.teams[1].players[playerTwoIndex]);
    this.rounds.push(round);
    return round;
  }

  currentRound() {
    if (!this.rounds || this.rounds.length === 0) {
      this.rounds = [];
      this.newRound(0, 0);
    }
    return this.rounds[this.currentRoundIndex()];
  }

  currentRoundIndex() {
    return this.rounds.length - 1;
  }

  canAddMore(player: number) {
    const score = this.currentRound().scores[player];
    return ((score.onBoard
      + score.offBoard
      + score.inHole) < this.gameType.gameSettings.bagsPerTurn);
  }


  addHole(player: number) {
    if (!this.canAddMore(player)) {
      return;
    }
    this.currentRound().scores[player].inHole += 1;
  }

  removeHole(player: number) {
    if (this.currentRound().scores[player].inHole > 0) {
      this.currentRound().scores[player].inHole -= 1;
    }
  }


  onBoard(player: number) {
    if (!this.canAddMore(player)) {
      return;
    }
    this.currentRound().scores[player].onBoard += 1;
  }

  offBoard(player: number) {
    if (this.currentRound().scores[player].onBoard > 0) {
      this.currentRound().scores[player].onBoard -= 1;
    }
  }

  currentScore(player: number) {
    const holes = (this.currentScoreItem(player).inHole * this.gameType.points.inHole);
    const boards = (this.currentScoreItem(player).onBoard * this.gameType.points.onBoard);
    return holes + boards;
  }

  currentScoreItem(player: number) {
    return this.currentRound().scores[player];
  }

  private roundPosition() {
    return this.rounds.length - 1;
  }
}
