import {Injectable} from '@angular/core';
import {GameType} from '../models/gametype';
import {GameSettings} from '../models/gamesettings';
import {Points} from '../models/points';

@Injectable()
export class GameTypesService {

  gameTypes: GameType[] = [];

  currentGameType: GameType;

  constructor() {


    const types = localStorage.getArray(GameType.uniqueName, GameType);
    if (!types || types.length === 0) {

      const newType = new GameType();
      newType.gameSettings = new GameSettings();
      newType.name = 'Classic 21';
      newType.points = new Points();
      newType.id = 1;
      this.gameTypes.push(newType);

      const seventwentyOne = new GameType();
      seventwentyOne.gameSettings = new GameSettings();
      seventwentyOne.name = '7/21';
      seventwentyOne.points = new Points();
      seventwentyOne.points.bagsCancelEachOther = false;
      seventwentyOne.gameSettings.maxRounds = 1;
      seventwentyOne.gameSettings.bagsPerTurn = 7;
      seventwentyOne.id = 2;
      this.gameTypes.push(seventwentyOne);

      const noCancels = new GameType();
      noCancels.gameSettings = new GameSettings();
      noCancels.name = 'No Cancels';
      noCancels.points = new Points();
      noCancels.points.bagsCancelEachOther = true;
      noCancels.points.boardsCancelHoles = false;
      noCancels.points.holesCancelBoards = false;
      noCancels.id = 3;
      this.gameTypes.push(noCancels);

      this.currentGameType = this.gameTypes[0];
      localStorage.saveArray(this.gameTypes);
    } else {
      this.gameTypes = types;
      this.currentGameType = this.gameTypes[0];
    }

    this.currentGameType = this.gameTypes[0];
  }

  setCurrentGameType(gameType: GameType) {
    this.currentGameType = gameType;
  }

  saveGameType(gameType: GameType) {
    if (gameType.id === -1) {
      gameType.id = this.gameTypes.nextId();
      this.gameTypes.push(gameType);
    } else {
      const index = this.gameTypes.findIndex((value => {
        return value.id === gameType.id;
      }));

      if (index > -1) {
        this.gameTypes[index] = gameType;
      }
    }
    localStorage.saveArray(this.gameTypes);
  }
}
