import {deserialize, serialize} from 'cerialize';
import {BaseModel} from './BaseModel';

export class User extends BaseModel {

  static uniqueName = 'User';
  nameInstance = User.uniqueName;

  @serialize
  @deserialize
  name = '';
  @serialize
  @deserialize
  id = -1;


  constructor(name: string, id: number = -1) {
    super();
    this.name = name;
    this.id = id;
  }
}
