import {User} from './user';
import {deserialize, serialize} from 'cerialize';
import {BaseModel} from './BaseModel';

export class Score extends BaseModel {

  static uniqueName = 'Score';
  nameInstance = Score.uniqueName;

  @serialize
  @deserialize
  userId: number;

  @serialize
  @deserialize
  onBoard = 0;

  @serialize
  @deserialize
  inHole = 0;

  @serialize
  @deserialize
  offBoard = 0;

  @serialize
  @deserialize
  roundScore = -1;

  constructor(user: User) {
    super();
    if (user) {
      this.userId = user.id;
    }
  }

  resetScore() {
    this.onBoard = 0;
    this.inHole = 0;
    this.offBoard = 0;
  }
}
