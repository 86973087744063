import {Component, OnInit} from '@angular/core';
import {UsersService} from '../services/users.service';
import {ActivatedRoute} from '@angular/router';
import {GameService} from '../services/game.service';
import {Game} from '../models/game';

@Component({
  selector: 'app-player-details',
  templateUrl: './player-details.component.html',
  styleUrls: ['./player-details.component.scss']
})
export class PlayerDetailsComponent implements OnInit {

  pastGames: Game[];
  totalBags = 0;
  totalBoards = 0;
  totalHoles = 0;
  id = 0;

  constructor(public userSerivce: UsersService, private activatedRoute: ActivatedRoute, private gameService: GameService) {
  }

  user() {
    return this.userSerivce.users.firstOrNull(user => {
      return user.id === +this.activatedRoute.snapshot.paramMap.get('id');
    });

  }

  ngOnInit() {
    this.id = +this.activatedRoute.snapshot.paramMap.get('id');
    this.pastGames = this.gameService.pastGames().filter(game => {
      for (let i = 0; i < game.teams.length; i++) {
        const team = game.teams[i];
        for (let j = 0; j < team.players.length; j++) {
          if (team.players[j].id === this.id) {
            game.rounds.forEach(round => {
              round.scores.forEach(score => {
                if (score.userId === this.id) {
                  this.totalHoles += score.inHole;
                  this.totalBoards += score.onBoard;
                  this.totalBags += game.gameType.gameSettings.bagsPerTurn;
                }
              });
            });
            return true;
          }
        }
      }
      return false;
    });


  }

}
