import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InputType} from '../component/custom-input/custom-input.component';
import {StaticContextService} from '../service/static-context.service';

@Component({
  selector: 'app-scan-input',
  templateUrl: './scan-input.component.html',
  styleUrls: ['./scan-input.component.scss']
})
export class ScanInputComponent implements OnInit {


  form: FormGroup;

  testData: any;


  constructor(private formBuilder: FormBuilder, public staticContext: StaticContextService) {
  }

  ngOnInit() {

    const scans = [
      ScanType.CT,
      ScanType.MAMMOGRAM,
      ScanType.MRI,
      ScanType.XRAY
    ];

    console.log(ScanType);

    this.testData = [
      {
        controlName: 'date',
        default: new Date(),
        validators: Validators.required,
        title: 'Date',
        type: InputType.DATE,
        maxDate: new Date()
      }, {
        controlName: 'scanType',
        default: ScanType.MAMMOGRAM,
        validators: Validators.required,
        title: 'Scan Type',
        placeholder: 'Select Scan',
        type: InputType.SELECT,
        multipleList: Object.values(ScanType),
        listText: (item) => {
          return item;
        }
      },
      {
        controlName: 'results',
        default: '',
        title: 'Results',
        placeholder: 'Results of the Scan',
        type: InputType.TEXT_AREA
      },
      {
        controlName: 'notes',
        default: '',
        title: 'Notes',
        placeholder: 'Leave a note',
        type: InputType.TEXT_AREA
      }
    ];

    const controlConfig = {};

    this.testData.forEach(value => {
      controlConfig[value.controlName] = [value.default, value.validators];
    });


    this.form = this.formBuilder.group(controlConfig);

  }

}

export enum ScanType {
  XRAY = 'X-Ray',
  CT = 'CT',
  MRI = 'MRI',
  MAMMOGRAM = 'Mammogram'
}
