import {deserialize, serialize} from 'cerialize';
import {BaseModel} from './BaseModel';

export class GameSettings extends BaseModel {

  static uniqueName = 'GameSettings';
  nameInstance = GameSettings.uniqueName;

  @serialize
  @deserialize
  bagsPerTurn = 4;

  @serialize
  @deserialize
  winningScore = 21;

  @serialize
  @deserialize
  bust = true;

  @serialize
  @deserialize
  bustScoreTo = 15;

  @serialize
  @deserialize
  maxRounds = 0;

}
