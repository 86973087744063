import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatAutocompleteSelectedEvent, MatChipInputEvent} from '@angular/material';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import {Observable} from 'rxjs';
import {map, startWith} from 'rxjs/operators';

@Component({
  selector: 'app-custom-input',
  templateUrl: './custom-input.component.html',
  styleUrls: ['./custom-input.component.scss']
})
export class CustomInputComponent implements OnInit {

  @Input()
  item: any;

  @Input()
  form: FormGroup;

  @Input()
  title = '';

  @Input()
  placeholder = this.title;

  @Input()
  controlName = '';

  @Input()
  type = InputType.INPUT;

  @Input()
  maxDate = null;

  @Input()
  multipleList = null;

  @Input()
  listText: (any) => string = null;

  inputType = InputType;

  @Input()
  classWrapper = 'flat-card-small';

  @Input()
  buttonClick: () => void = (() => {
  });

  @Input()
  editable = true;

  isTrue = true;
  addOnBlur = false;
  formCtl = new FormControl();

  separatorKeysCodes: number[] = [ENTER, COMMA];

  @Input()
  allListUnfiltered: any[] = [];

  filteredList: Observable<any[]>;


  @ViewChild('chipInput') chipInput: ElementRef;

  constructor() {
  }

  ngOnInit() {
    if (this.item) {
      if (this.item.hasOwnProperty('placeholder')) {
        this.placeholder = this.item.placeholder;
      }
      if (this.item.hasOwnProperty('title')) {
        this.title = this.item.title;
        if (!this.placeholder) {
          this.placeholder = this.title;
        }
      }
      if (this.item.hasOwnProperty('controlName')) {
        this.controlName = this.item.controlName;
      }
      if (this.item.hasOwnProperty('type')) {
        this.type = this.item.type;
      }
      if (this.item.hasOwnProperty('maxDate')) {
        this.maxDate = this.item.maxDate;
      }
      if (this.item.hasOwnProperty('multipleList')) {
        this.multipleList = this.item.multipleList;
      }
      if (this.item.hasOwnProperty('listText')) {
        this.listText = this.item.listText;
      }
      if (this.item.hasOwnProperty('classWrapper')) {
        this.classWrapper = this.item.classWrapper;
      }
      if (this.item.hasOwnProperty('buttonClick')) {
        this.buttonClick = this.item.buttonClick;
      }
      if (this.item.hasOwnProperty('editable')) {
        this.editable = this.item.editable;
      }
    }

    this.filteredList = this.formCtl.valueChanges.pipe(
      startWith(null),
      map((fruit: string | null) => fruit ? this._filter(fruit) : this.allListUnfiltered.slice()));

  }

  getControl() {
    return this.form.controls[this.controlName];
  }

  getValue() {
    let value = this.getControl().value;
    if (isNaN(value)) {
      value = 0;
    }
    return value;
  }

  add() {
    let value = this.getControl().value;
    if (isNaN(value)) {
      value = 0;
    }
    this.getControl().setValue(value + 1);
  }

  minus() {
    let value = this.getControl().value;
    if (isNaN(value)) {
      value = 0;
    }
    if (value > 0) {
      this.getControl().setValue(value - 1);
    }
  }

  addChip(event: MatChipInputEvent): void {

    const input = event.input;
    const value = event.value;

    // Add our fruit
    if ((value || '').trim()) {
      if (!this.getControl().value) {
        this.getControl().setValue([]);
      }
      this.getControl().value.push(value.trim());
    }

    // Reset the input value
    if (input) {
      input.value = '';
    }

    this.formCtl.setValue(null);
  }

  removeChip(chip: any): void {
    const item = this.getControl().value;
    if (!item || item.constructor !== [].constructor) {
      return;
    }
    const index = item.indexOf(chip);

    if (index >= 0) {
      item.splice(index, 1);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if (!this.getControl().value) {
      this.getControl().setValue([]);
    }
    this.getControl().value.push(event.option.viewValue);
    this.chipInput.nativeElement.value = '';
    this.formCtl.setValue(null);
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allListUnfiltered.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }
}

export enum InputType {
  INPUT,
  INCREMENT,
  TEXT_AREA,
  MULTIPLE,
  DATE,
  SELECT,
  BUTTON,
  VIEW,
  CHIPS
}
