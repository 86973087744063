import {Score} from './score';
import {User} from './user';
import {deserialize, deserializeAs, serialize, serializeAs} from 'cerialize';
import {BaseModel} from './BaseModel';
import {GameType} from './gametype';

export class Round extends BaseModel {
  static uniqueName = 'Round';
  nameInstance = Round.uniqueName;

  @serialize
  @deserialize
  roundId: number;
  // Two Players
  @serializeAs(Score)
  @deserializeAs(Score)
  scores: Score[] = [];


  constructor(roundId: number, userOne: User, userTwo: User) {
    super();
    this.roundId = roundId;
    this.scores = [new Score(userOne), new Score(userTwo)];
  }

  currentScore(gameType: GameType, teamOne: number = 0, teamTwo: number = teamOne === 0 ? 1 : 0) {
    if (this.scores[teamOne].roundScore !== -1) {
      //  return this.scores[teamOne].roundScore;
    }
    const oneBoard = (this.scores[teamOne].onBoard * gameType.points.onBoard);
    const oneHole = (this.scores[teamOne].inHole * gameType.points.inHole);
    const twoBoard = (this.scores[teamTwo].onBoard * gameType.points.onBoard);
    const twoHole = (this.scores[teamTwo].inHole * gameType.points.inHole);

    const oneTotal = oneBoard + oneHole;
    const twoTotal = twoBoard + twoHole;

    if (!gameType.points.bagsCancelEachOther) {
      this.scores[teamOne].roundScore = oneBoard + oneHole;
      return this.scores[teamOne].roundScore;
    }

    let boards = oneBoard - twoBoard;
    if (!gameType.points.boardsCancelHoles && boards < 0) {
      boards = 0;
    }
    let holes = oneHole - twoHole;
    if (!gameType.points.holesCancelBoards && holes < 0) {
      holes = 0;
    }
    const score = boards + holes;
    this.scores[teamOne].roundScore = (score < 0) ? 0 : score;
    return this.scores[teamOne].roundScore;
  }

  scoreForPlayer(gameType: GameType, team: number, opp: number = team === 1 ? 0 : 1) {
    return this.currentScore(gameType, team, opp);
  }
}
