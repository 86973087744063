import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InputType} from '../component/custom-input/custom-input.component';

@Component({
  selector: 'app-radiation-input',
  templateUrl: './radiation-input.component.html',
  styleUrls: ['./radiation-input.component.scss']
})
export class RadiationInputComponent implements OnInit {

  form: FormGroup;

  testData: any;


  radiationLocations = [
    {location: 'Head', isChecked: false},
    {location: 'Neck', isChecked: false},
    {location: 'Shoulder', isChecked: false},
    {location: 'Back', isChecked: false},
    {location: 'Chest', isChecked: false},
    {location: 'Breast', isChecked: false},
    {location: 'Abdomen', isChecked: false},
    {location: 'Pelvis', isChecked: false},
    {location: 'Arm', isChecked: false},
    {location: 'Leg', isChecked: false},
    {location: 'Bilateral', isChecked: false},
    {location: 'Right', isChecked: false},
    {location: 'Left', isChecked: false}
  ];


  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.testData = [
      {
        controlName: 'date',
        default: new Date(),
        validators: Validators.required,
        title: 'Date',
        type: InputType.DATE,
        maxDate: new Date()
      }, {
        controlName: 'radiation',
        default: null,
        validators: Validators.required,
        title: 'Radiation Location',
        placeholder: 'Select Location',
        type: InputType.MULTIPLE,
        multipleList: this.radiationLocations,
        listText: (item) => {
          return item.location;
        }
      },
      {
        controlName: 'amount',
        default: 0,
        title: 'Amount',
        type: InputType.INCREMENT
      },
      {
        controlName: 'notes',
        default: '',
        title: 'Notes',
        placeholder: 'Leave a note',
        type: InputType.TEXT_AREA
      }
    ];

    const controlConfig = {};

    this.testData.forEach(value => {
      controlConfig[value.controlName] = [value.default, value.validators];
    });


    this.form = this.formBuilder.group(controlConfig);

  }
}
