import {Component, OnInit} from '@angular/core';
import {chemocare} from '../data/chemocare';
import {FuseConfigService} from '../../../../../@fuse/services/config.service';
import {StaticContextService} from '../service/static-context.service';
import {Location} from '@angular/common';

@Component({
  selector: 'app-drug-list',
  templateUrl: './drug-list.component.html',
  styleUrls: ['./drug-list.component.scss']
})
export class DrugListComponent implements OnInit {

  constructor(public config: FuseConfigService, public location: Location, private staticContext: StaticContextService) {
    this.config.setConfig({
      layout: {
        navigation: 'none',
        toolbar: 'none',
        footer: 'none'
      }
    });

  }

  druglist: any;
  searchBar = '';

  ngOnInit() {
    this.druglist = chemocare;
  }

  selected(drugItem: any) {
    this.staticContext.drugInput.drugName = drugItem.title;
    this.location.back();
  }

  close() {
    this.location.back();
  }


}
