import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {InputType} from '../component/custom-input/custom-input.component';

@Component({
  selector: 'app-surgery-input',
  templateUrl: './surgery-input.component.html',
  styleUrls: ['./surgery-input.component.scss']
})
export class SurgeryInputComponent implements OnInit {

  form: FormGroup;

  testData: any;


  constructor(private formBuilder: FormBuilder) {
  }

  ngOnInit() {

    this.testData = [
      {
        controlName: 'date',
        default: new Date(),
        validators: Validators.required,
        title: 'Date',
        type: InputType.DATE,
        maxDate: new Date()
      }, {
        controlName: 'surgeryType',
        default: '',
        validators: Validators.required,
        title: 'Surgery Type',
        placeholder: 'Type of Surgery'
      },
      {
        controlName: 'surgeonName',
        default: '',
        validators: Validators.required,
        title: 'Surgeon Name',
        placeholder: 'Surgeon\'s Name'
      },
      {
        controlName: 'siteOfSurgery',
        default: '',
        validators: Validators.required,
        title: 'Site of Surgery',
        placeholder: 'Name of Hospital, Clinic, etc...'
      },
      {
        controlName: 'notes',
        default: '',
        title: 'Notes',
        placeholder: 'Leave a note',
        type: InputType.TEXT_AREA
      }
    ];

    const controlConfig = {};

    this.testData.forEach(value => {
      controlConfig[value.controlName] = [value.default, value.validators];
    });


    this.form = this.formBuilder.group(controlConfig);

  }


}
