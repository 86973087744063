export const chemocare = [
  {
    'drug': '5-Azacitidine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/5-azacitidine.aspx',
    'title': '5-Azacitidine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '6-MP',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/6-mp.aspx',
    'title': '6-MP',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '6-Mercaptopurine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/6-mercaptopurine.aspx',
    'title': '6-Mercaptopurine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '6-TG',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/6-tg.aspx',
    'title': '6-TG',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '6-Thioguanine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/6-thioguanine.aspx',
    'title': '6-Thioguanine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '13-cis-Retinoic Acid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/13-cis-retinoic-acid.aspx',
    'title': '13-cis-Retinoic Acid',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Alimta',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/alimta.aspx',
    'title': 'ALIMTA',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Atra',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/atra.aspx',
    'title': 'ATRA',
    'genericName': 'Tretinoin , Vesanoid , All-trans retinoic acid',
    'tradeName': 'Tretinoin , Vesanoid , All-trans retinoic acid'
  },
  {
    'drug': 'Abemaciclib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/abemaciclib.aspx',
    'title': 'Abemaciclib',
    'genericName': null,
    'tradeName': 'Verzenio'
  },
  {
    'drug': 'Abiraterone acetate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/abiraterone-acetate.aspx',
    'title': 'Abiraterone Acetate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Abraxane',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/abraxane.aspx',
    'title': 'Abraxane',
    'genericName': 'Paclitaxel protein-bound',
    'tradeName': null
  },
  {
    'drug': 'Accutane',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/accutane.aspx',
    'title': 'Accutane',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Adcetris',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/adcetris.aspx',
    'title': 'Adcetris',
    'genericName': 'Brentuximab vedotin',
    'tradeName': null
  },
  {
    'drug': 'Ado-Trastuzumab Emtansine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ado-trastuzumab-emtansine.aspx',
    'title': 'Ado-Trastuzumab Emtansin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Afatinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/afatinib.aspx',
    'title': 'Afatinib',
    'genericName': 'Afatinib',
    'tradeName': 'Gilotrif'
  },
  {
    'drug': 'Afinitor',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/afinitor.aspx',
    'title': 'Afinitor',
    'genericName': 'Everolimus',
    'tradeName': 'Afinitor'
  },
  {
    'drug': 'Agrylin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/agrylin.aspx',
    'title': 'Agrylin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ala-Cort',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ala-cort.aspx',
    'title': 'Ala-Cort',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Aldesleukin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/aldesleukin.aspx',
    'title': 'Aldesleukin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Alecensa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/alectinib.aspx',
    'title': 'Alectinib',
    'genericName': null,
    'tradeName': 'Alecensa'
  },
  {
    'drug': 'Alemtuzumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/alemtuzumab.aspx',
    'title': 'Alemtuzumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Alitretinoin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/alitretinoin.aspx',
    'title': 'Alitretinoin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Alkaban-AQ',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/alkaban-aq.aspx',
    'title': 'Alkaban-AQ',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Alkeran',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/alkeran.aspx',
    'title': 'Alkeran',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'All-transretinoic Acid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/all-transretinoic-acid.aspx',
    'title': 'All-Trans Retinoic Acid',
    'genericName': 'Tretinoin',
    'tradeName': 'Vesanoid'
  },
  {
    'drug': 'Altretamine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/altretamine.aspx',
    'title': 'Altretamine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Amifostine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/amifostine.aspx',
    'title': 'Amifostine',
    'genericName': null,
    'tradeName': 'Ethyol'
  },
  {
    'drug': 'Aminoglutethimide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/aminoglutethimide.aspx',
    'title': 'Aminoglutethimide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Anagrelide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/anagrelide.aspx',
    'title': 'Anagrelide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Anandron',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/anandron.aspx',
    'title': 'Anandron',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Anastrozole',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/anastrozole.aspx',
    'title': 'Anastrozole',
    'genericName': null,
    'tradeName': 'Arimidex'
  },
  {
    'drug': 'Apalutamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/apalutamide.aspx',
    'title': 'Apalutamide',
    'genericName': null,
    'tradeName': 'Erleada'
  },
  {
    'drug': 'Ara-C',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ara-c.aspx',
    'title': 'Ara-C',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Arabinosylcytosine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/arabinosylcytosine.aspx',
    'title': 'Arabinosylcytosine',
    'genericName': 'Cytarabine',
    'tradeName': null
  },
  {
    'drug': 'Aranesp',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/aranesp.aspx',
    'title': 'Aranesp',
    'genericName': 'Darbepoetin Alfa',
    'tradeName': 'Aranesp'
  },
  {
    'drug': 'Aredia',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/aredia.aspx',
    'title': 'Aredia',
    'genericName': 'Pamidronate',
    'tradeName': null
  },
  {
    'drug': 'Arimidex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/arimidex.aspx',
    'title': 'Arimidex',
    'genericName': 'Anastrozole',
    'tradeName': 'Arimidex'
  },
  {
    'drug': 'Aromasin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/aromasin.aspx',
    'title': 'Aromasin',
    'genericName': 'Exemestane (ex e MES tane)',
    'tradeName': 'Aromasin'
  },
  {
    'drug': 'Arranon',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/arranon.aspx',
    'title': 'Arranon',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Trisenox',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Trisenox.aspx',
    'title': 'Arsenic Trioxide',
    'genericName': 'Arsenic Trioxide',
    'tradeName': 'Trisenox'
  },
  {
    'drug': 'Arsenic Trioxide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/arsenic-trioxide.aspx',
    'title': 'Arsenic Trioxide',
    'genericName': null,
    'tradeName': 'Trisenox'
  },
  {
    'drug': 'Arzerra',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/arzerra.aspx',
    'title': 'Arzerra',
    'genericName': ' Ofatumumab',
    'tradeName': null
  },
  {
    'drug': 'Asparaginase',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/asparaginase.aspx',
    'title': 'Asparaginase',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Atezolizumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/atezolizumab.aspx',
    'title': 'Atezolizumab',
    'genericName': null,
    'tradeName': 'Tecentriq'
  },
  {
    'drug': 'Avastin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/avastin.aspx',
    'title': 'Avastin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Avelumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/avelumab.aspx',
    'title': 'Avelumab',
    'genericName': null,
    'tradeName': 'Bavencio'
  },
  {
    'drug': 'Axicabtagene Ciloleucel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/axicabtagene-cilileucel.aspx',
    'title': 'Axicabtagene Ciloleucel',
    'genericName': null,
    'tradeName': 'Yescarta'
  },
  {
    'drug': 'Axitinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/axitinib.aspx',
    'title': 'Axitinib',
    'genericName': null,
    'tradeName': 'Inlyta'
  },
  {
    'drug': 'Vidaza',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vidaza.aspx',
    'title': 'Azacitadine',
    'genericName': 'Azacitadine',
    'tradeName': null
  },
  {
    'drug': 'Azacitidine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/azacitidine.aspx',
    'title': 'Azacitidine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Bcg',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bcg.aspx',
    'title': 'BCG (Bacillus Calmette-Guerin)',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Bexxar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bexxar.aspx',
    'title': 'BEXXAR',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Beleodaq',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/belinostat.aspx',
    'title': 'Belinostat',
    'genericName': null,
    'tradeName': 'Beleodaq'
  },
  {
    'drug': 'Bendamustine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bendamustine.aspx',
    'title': 'Bendamustine',
    'genericName': 'Bendamustine Hydrochloride , Treanda, Bendekatm',
    'tradeName': 'Bendamustine Hydrochloride , Treanda, Bendekatm'
  },
  {
    'drug': 'Bevacizumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bevacizumab.aspx',
    'title': 'Bevacizumab',
    'genericName': null,
    'tradeName': 'Avastin '
  },
  {
    'drug': 'Bexarotene',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bexarotene.aspx',
    'title': 'Bexarotene',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Bicnu',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bicnu.aspx',
    'title': 'BiCNU',
    'genericName': 'Carmustine',
    'tradeName': null
  },
  {
    'drug': 'Bicalutamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bicalutamide.aspx',
    'title': 'Bicalutamide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Blenoxane',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/blenoxane.aspx',
    'title': 'Blenoxane',
    'genericName': 'Bleomycin',
    'tradeName': 'Blenoxane'
  },
  {
    'drug': 'Bleomycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bleomycin.aspx',
    'title': 'Bleomycin',
    'genericName': null,
    'tradeName': 'Blenoxane'
  },
  {
    'drug': 'Blinatumomab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/blinatumomab.aspx',
    'title': 'Blinatumomab',
    'genericName': null,
    'tradeName': 'Blincyto'
  },
  {
    'drug': 'Blincyto',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/blincyto.aspx',
    'title': 'Blincyto',
    'genericName': null,
    'tradeName': 'Blincyto'
  },
  {
    'drug': 'Bortezomib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bortezomib.aspx',
    'title': 'Bortezomib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Bosulif',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bosulif.aspx',
    'title': 'Bosulif',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Bosutinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/bosutinib.aspx',
    'title': 'Bosutinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Brentuximab Vedotin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/brentuximab-vedotin.aspx',
    'title': 'Brentuximab Vedotin',
    'genericName': null,
    'tradeName': 'Adcetris'
  },
  {
    'drug': 'Alunbrig',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/brigatinib.aspx',
    'title': 'Brigatinib',
    'genericName': null,
    'tradeName': 'Alunbrig'
  },
  {
    'drug': 'Busulfan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/busulfan.aspx',
    'title': 'Busulfan',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Busulfex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/busulfex.aspx',
    'title': 'Busulfex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'CCI-779',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cci-779.aspx',
    'title': 'CCI-779',
    'genericName': 'Temsirolimus , Torisel',
    'tradeName': 'Temsirolimus , Torisel'
  },
  {
    'drug': 'Ccnu',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ccnu.aspx',
    'title': 'CCNU',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cddp',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cddp.aspx',
    'title': 'CDDP',
    'genericName': 'Cisplatin',
    'tradeName': 'Platinol, Platinol-AQ'
  },
  {
    'drug': 'Cometriq',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cometriq.aspx',
    'title': 'COMETRIQ',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cpt-11',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cpt-11.aspx',
    'title': 'CPT-11',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cyramza',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cyramza.aspx',
    'title': 'CYRAMZA',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cabazitaxel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cabazitaxel.aspx',
    'title': 'Cabazitaxel',
    'genericName': null,
    'tradeName': 'Jevtana'
  },
  {
    'drug': 'Cabozantinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cabozantinib.aspx',
    'title': 'Cabozantinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Calcium Leucovorin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/calcium-leucovorin.aspx',
    'title': 'Calcium Leucovorin',
    'genericName': 'Leucovorin , Citrovorum Factor, Folinic Acid',
    'tradeName': null
  },
  {
    'drug': 'Campath',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/campath.aspx',
    'title': 'Campath',
    'genericName': 'Alemtuzumab',
    'tradeName': null
  },
  {
    'drug': 'Camptosar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/camptosar.aspx',
    'title': 'Camptosar',
    'genericName': 'Irinotecan , Camptothecin-11,CPT-11',
    'tradeName': null
  },
  {
    'drug': 'Camptothecin-11',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/camptothecin-11.aspx',
    'title': 'Camptothecin-11',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Capecitabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/capecitabine.aspx',
    'title': 'Capecitabine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Caprelsa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/caprelsa.aspx',
    'title': 'Caprelsa',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Carac',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/carac.aspx',
    'title': 'Carac',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Carboplatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/carboplatin.aspx',
    'title': 'Carboplatin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Carfilzomib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/carfilzomib.aspx',
    'title': 'Carfilzomib',
    'genericName': null,
    'tradeName': 'Kyprolis'
  },
  {
    'drug': 'Carmustine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/carmustine.aspx',
    'title': 'Carmustine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Carmustine Wafer',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/carmustine-wafer.aspx',
    'title': 'Carmustine Wafer',
    'genericName': null,
    'tradeName': 'Gliadel wafer'
  },
  {
    'drug': 'Gliadel Wafer',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gliadel-wafer.aspx',
    'title': 'Carmustine Wafer',
    'genericName': null,
    'tradeName': 'Gliadel wafer'
  },
  {
    'drug': 'Casodex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/casodex.aspx',
    'title': 'Casodex',
    'genericName': 'Bicalutamide',
    'tradeName': null
  },
  {
    'drug': 'Ceenu',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ceenu.aspx',
    'title': 'CeeNU',
    'genericName': 'Lomustine',
    'tradeName': null
  },
  {
    'drug': 'Ceritinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ceritinib.aspx',
    'title': 'Ceritinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cerubidine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cerubidine.aspx',
    'title': 'Cerubidine',
    'genericName': 'Daunorubicin',
    'tradeName': 'Cerubidine'
  },
  {
    'drug': 'Cetuximab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cetuximab.aspx',
    'title': 'Cetuximab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'C225',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/c225.aspx',
    'title': 'Cetuximab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Chlorambucil',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/chlorambucil.aspx',
    'title': 'Chlorambucil',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cisplatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cisplatin.aspx',
    'title': 'Cisplatin',
    'genericName': null,
    'tradeName': 'Platinol, Platinol-AQ'
  },
  {
    'drug': 'Citrovorum Factor',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/citrovorum-factor.aspx',
    'title': 'Citrovorum Factor',
    'genericName': 'Leucovorin , Calcium Leucovorin, Folinic Acid',
    'tradeName': null
  },
  {
    'drug': 'Leustatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Leustatin.aspx',
    'title': 'Cladribine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '2-Chlorodeoxyadenosine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/2-chlorodeoxyadenosine.aspx',
    'title': 'Cladribine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '2-CdA',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/2-cda.aspx',
    'title': 'Cladribine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cladribine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cladribine.aspx',
    'title': 'Cladribine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Clofarabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/clofarabine.aspx',
    'title': 'Clofarabine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Clolar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/clolar.aspx',
    'title': 'Clolar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cobimetinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cobimetinib.aspx',
    'title': 'Cobimetinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cortisone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cortisone.aspx',
    'title': 'Cortisone',
    'genericName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,  Solu-Cortef , Hydrocort Acetate , Lanacort , Other Names: Hydrocortisone Sodium Succinate, Hydrocortisone Sodium Phosphate',
    'tradeName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,  Solu-Cortef , Hydrocort Acetate , Lanacort , Other Names: Hydrocortisone Sodium Succinate, Hydrocortisone Sodium Phosphate'
  },
  {
    'drug': 'Crizotinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/crizotinib.aspx',
    'title': 'Crizotinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cyclophosphamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cyclophosphamide.aspx',
    'title': 'Cyclophosphamide',
    'genericName': null,
    'tradeName': 'Cytoxan, Neosar'
  },
  {
    'drug': 'Cytadren',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cytadren.aspx',
    'title': 'Cytadren',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cytarabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cytarabine.aspx',
    'title': 'Cytarabine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cytarabine Liposomal',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cytarabine-liposomal.aspx',
    'title': 'Cytarabine Liposomal',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Cytosar-U',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cytosar-u.aspx',
    'title': 'Cytosar-U',
    'genericName': 'Cytarabine',
    'tradeName': null
  },
  {
    'drug': 'Cytoxan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cytoxan.aspx',
    'title': 'Cytoxan',
    'genericName': 'Cyclophosphamide',
    'tradeName': 'Other Neosar'
  },
  {
    'drug': 'Dhad',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dhad.aspx',
    'title': 'DHAD',
    'genericName': 'Mitoxantrone',
    'tradeName': null
  },
  {
    'drug': 'Dic',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dic.aspx',
    'title': 'DIC',
    'genericName': 'Dacarbazine',
    'tradeName': null
  },
  {
    'drug': 'DTIC',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dtic.aspx',
    'title': 'DTIC',
    'genericName': 'Dacarbazine , DTIC-Dome  , DIC,Imidazole Carboxamide',
    'tradeName': 'Dacarbazine , DTIC-Dome  , DIC,Imidazole Carboxamide'
  },
  {
    'drug': 'Dtic-Dome',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dtic-dome.aspx',
    'title': 'DTIC-Dome',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dabrafenib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dabrafenib.aspx',
    'title': 'Dabrafenib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dacarbazine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dacarbazine.aspx',
    'title': 'Dacarbazine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dacogen',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dacogen.aspx',
    'title': 'Dacogen',
    'genericName': 'Decitabine',
    'tradeName': null
  },
  {
    'drug': 'Cosmegen',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/cosmegen.aspx',
    'title': 'Dactinomycin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Actinomycin-D',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/actinomycin-d.aspx',
    'title': 'Dactinomycin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dactinomycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dactinomycin.aspx',
    'title': 'Dactinomycin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Daratumumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daratumumab.aspx',
    'title': 'Daratumumab',
    'genericName': null,
    'tradeName': 'Darzalex'
  },
  {
    'drug': 'Darbepoetin Alfa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/darbepoetin-alfa.aspx',
    'title': 'Darbepoetin Alfa',
    'genericName': 'Darbepoetin Alfa',
    'tradeName': 'Aranesp'
  },
  {
    'drug': 'Dasatinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dasatinib.aspx',
    'title': 'Dasatinib',
    'genericName': null,
    'tradeName': 'SPRYCEL'
  },
  {
    'drug': 'DaunoXome',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daunoXome.aspx',
    'title': 'DaunoXome',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Daunomycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daunomycin.aspx',
    'title': 'Daunomycin',
    'genericName': 'Daunorubicin',
    'tradeName': 'Cerubidine'
  },
  {
    'drug': 'Daunorubicin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daunorubicin.aspx',
    'title': 'Daunorubicin',
    'genericName': null,
    'tradeName': 'Cerubidine'
  },
  {
    'drug': 'daunorubicin-hydrochloride',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daunorubicin-hydrochloride.aspx',
    'title': 'Daunorubicin Hydrochloride',
    'genericName': 'Daunorubicin',
    'tradeName': 'Cerubidine'
  },
  {
    'drug': 'Daunorubicin Liposomal',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daunorubicin-liposomal.aspx',
    'title': 'Daunorubicin Liposomal',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Daunorubicin Cytarabine (Liposomal)',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/daunorubicin-and-cytarabine-liposomal.aspx',
    'title': 'Daunorubicin and Cytarabine (Liposomal)',
    'genericName': null,
    'tradeName': 'VyxeosTM'
  },
  {
    'drug': 'Decadron',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/decadron.aspx',
    'title': 'Decadron',
    'genericName': 'Dexamethasone , Dexasone, Diodex, Hexadrol, Maxidex , Dexamethasone Sodium Phosphate, Dexamethasone Acetate',
    'tradeName': null
  },
  {
    'drug': 'Decitabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/decitabine.aspx',
    'title': 'Decitabine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Delta-Cortef',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/delta-cortef.aspx',
    'title': 'Delta-Cortef',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Deltasone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/deltasone.aspx',
    'title': 'Deltasone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Denileukin Diftitox',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/denileukin-diftitox.aspx',
    'title': 'Denileukin Diftitox',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Denosumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/denosumab.aspx',
    'title': 'Denosumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'DepoCyt',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/depoCyt.aspx',
    'title': 'DepoCyt',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dexamethasone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dexamethasone.aspx',
    'title': 'Dexamethasone',
    'genericName': null,
    'tradeName': '  Decadron, Dexasone, Diodex, Hexadrol, Maxidex , dexamethasone sodium phosphate, dexamethasone acetate'
  },
  {
    'drug': 'Dexamethasone Acetate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dexamethasone-acetate.aspx',
    'title': 'Dexamethasone Acetate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dexamethasone Sodium Phosphate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dexamethasone-sodium-phosphate.aspx',
    'title': 'Dexamethasone Sodium Phosphate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dexasone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dexasone.aspx',
    'title': 'Dexasone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Dexrazoxane',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/dexrazoxane.aspx',
    'title': 'Dexrazoxane',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Diodex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/diodex.aspx',
    'title': 'Diodex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Docetaxel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/docetaxel.aspx',
    'title': 'Docetaxel',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Doxil',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/doxil.aspx',
    'title': 'Doxil',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Adriamycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/doxorubicin.aspx',
    'title': 'Doxorubicin',
    'genericName': null,
    'tradeName': 'Adriamycin, Rubex'
  },
  {
    'drug': 'Doxorubicin Liposomal',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/doxorubicin-liposomal.aspx',
    'title': 'Doxorubicin Liposomal',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Droxia',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/droxia.aspx',
    'title': 'Droxia',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Duralone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/duralone.aspx',
    'title': 'Duralone',
    'genericName': 'Methylprednisolone',
    'tradeName': null
  },
  {
    'drug': 'Durvalumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/durvalumab.aspx',
    'title': 'Durvalumab',
    'genericName': null,
    'tradeName': 'Imfinzi'
  },
  {
    'drug': 'Eculizumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/eculizumab.aspx',
    'title': 'Eculizumab',
    'genericName': 'Eculizumab',
    'tradeName': ' Soliris'
  },
  {
    'drug': 'Efudex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/efudex.aspx',
    'title': 'Efudex',
    'genericName': 'Fluorouracil , Carac TM, Fluoroplex  , 5-fluorouracil,5-FU',
    'tradeName': 'Fluorouracil , Carac TM, Fluoroplex  , 5-fluorouracil,5-FU'
  },
  {
    'drug': 'Ellence',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ellence.aspx',
    'title': 'Ellence',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Elotuzumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/elotuzumab.aspx',
    'title': 'Elotuzumab',
    'genericName': null,
    'tradeName': 'Empliciti'
  },
  {
    'drug': 'Eloxatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/eloxatin.aspx',
    'title': 'Eloxatin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Elspar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/elspar.aspx',
    'title': 'Elspar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Eltrombopag',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/eltrombopag.aspx',
    'title': 'Eltrombopag',
    'genericName': null,
    'tradeName': 'Promacta'
  },
  {
    'drug': 'Emcyt',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/emcyt.aspx',
    'title': 'Emcyt',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Enasidenib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/enasidenib.aspx',
    'title': 'Enasidenib',
    'genericName': null,
    'tradeName': 'Idhifa'
  },
  {
    'drug': 'Enzalutamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/enzalutamide.aspx',
    'title': 'Enzalutamide',
    'genericName': null,
    'tradeName': 'Xtandi'
  },
  {
    'drug': 'Epirubicin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/epirubicin.aspx',
    'title': 'Epirubicin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Epoetin Alfa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/epoetin-alfa.aspx',
    'title': 'Epoetin Alfa',
    'genericName': null,
    'tradeName': 'PROCRIT'
  },
  {
    'drug': 'Erbitux',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/erbitux.aspx',
    'title': 'Erbitux',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Eribulin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/eribulin.aspx',
    'title': 'Eribulin',
    'genericName': 'Eribulin (er i BUE lin)',
    'tradeName': 'Halaven'
  },
  {
    'drug': 'Erivedge',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/erivedge.aspx',
    'title': 'Erivedge',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Erlotinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/erlotinib.aspx',
    'title': 'Erlotinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Erwinia L-asparaginase',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/erwinia-l-asparaginase.aspx',
    'title': 'Erwinia L-asparaginase',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Estramustine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/estramustine.aspx',
    'title': 'Estramustine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ethyol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ethyol.aspx',
    'title': 'Ethyol',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Etopophos',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/etopophos.aspx',
    'title': 'Etopophos',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Etoposide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/etoposide.aspx',
    'title': 'Etoposide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Etoposide Phosphate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/etoposide-phosphate.aspx',
    'title': 'Etoposide Phosphate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Eulexin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/eulexin.aspx',
    'title': 'Eulexin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Everolimus',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/everolimus.aspx',
    'title': 'Everolimus',
    'genericName': null,
    'tradeName': 'Afinitor'
  },
  {
    'drug': 'Evista',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/evista.aspx',
    'title': 'Evista',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Exemestane',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/exemestane.aspx',
    'title': 'Exemestane',
    'genericName': null,
    'tradeName': 'Aromasin'
  },
  {
    'drug': 'Fudr',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fudr.aspx',
    'title': 'FUDR',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Fareston',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fareston.aspx',
    'title': 'Fareston',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Farydak',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/farydak.aspx',
    'title': 'Farydak',
    'genericName': 'Panobinostat',
    'tradeName': null
  },
  {
    'drug': 'Faslodex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/faslodex.aspx',
    'title': 'Faslodex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Femara',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/femara.aspx',
    'title': 'Femara',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Filgrastim',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/filgrastim.aspx',
    'title': 'Filgrastim',
    'genericName': null,
    'tradeName': 'Neupogen, Granix, Zarxio'
  },
  {
    'drug': 'Floxuridine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/floxuridine.aspx',
    'title': 'Floxuridine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Fludara',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fludara.aspx',
    'title': 'Fludara',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Fludarabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fludarabine.aspx',
    'title': 'Fludarabine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Fluoroplex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fluoroplex.aspx',
    'title': 'Fluoroplex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': '5-Fluorouracil',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fluorouracil.aspx',
    'title': 'Fluorouracil',
    'genericName': null,
    'tradeName': 'Adrucil , 5-fluorouracil, 5-FU'
  },
  {
    'drug': 'Fluorouracil (cream)',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fluorouracil-cream.aspx',
    'title': 'Fluorouracil Cream',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Fluoxymesterone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fluoxymesterone.aspx',
    'title': 'Fluoxymesterone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Flutamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/flutamide.aspx',
    'title': 'Flutamide',
    'genericName': null,
    'tradeName': 'Eulexin'
  },
  {
    'drug': 'Folinic Acid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/folinic-acid.aspx',
    'title': 'Folinic Acid',
    'genericName': 'Leucovorin , Calcium Leucovorin,Citrovorum Factor',
    'tradeName': null
  },
  {
    'drug': 'Folotyn',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/folotyn.aspx',
    'title': 'Folotyn',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Fulvestrant',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/fulvestrant.aspx',
    'title': 'Fulvestrant',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'G-Csf',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/g-csf.aspx',
    'title': 'G-CSF',
    'genericName': 'Filgrastim',
    'tradeName': 'Neupogen, Granix, Zarxio'
  },
  {
    'drug': 'Gm-Csf',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gm-csf.aspx',
    'title': 'GM-CSF',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Gazyva',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gazyva.aspx',
    'title': 'Gazyva',
    'genericName': 'Obinutuzumab',
    'tradeName': null
  },
  {
    'drug': 'Gefitinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gefitinib.aspx',
    'title': 'Gefitinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Gemcitabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gemcitabine.aspx',
    'title': 'Gemcitabine',
    'genericName': null,
    'tradeName': 'Gemzar'
  },
  {
    'drug': 'Gemtuzumab ozogamicin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gemtuzumab-ozogamicin.aspx',
    'title': 'Gemtuzumab Ozogamicin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Gemzar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gemzar.aspx',
    'title': 'Gemzar',
    'genericName': 'Gemcitabine',
    'tradeName': null
  },
  {
    'drug': 'Gilotrif',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gilotrif.aspx',
    'title': 'Gilotrif',
    'genericName': 'Afatinib',
    'tradeName': 'Gilotrif'
  },
  {
    'drug': 'Gleevec',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gleevec.aspx',
    'title': 'Gleevec',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Gleostine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/gleostine.aspx',
    'title': 'Gleostine',
    'genericName': 'Lomustine',
    'tradeName': null
  },
  {
    'drug': 'Goserelin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/goserelin.aspx',
    'title': 'Goserelin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Granulocyte - Colony Stimulating Factor',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/granulocyte-colony-stimulating-factor.aspx',
    'title': 'Granulocyte - Colony Stimulating Factor',
    'genericName': 'Filgrastim',
    'tradeName': 'Neupogen, Granix, Zarxio'
  },
  {
    'drug': 'Granulocyte Macrophage Colony Stimulating Factor',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/granulocyte-macrophage-colony-stimulating-factor.aspx',
    'title': 'Granulocyte Macrophage Colony Stimulating Factor',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Hmm',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hmm.aspx',
    'title': 'HMM',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Halaven',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/halaven.aspx',
    'title': 'Halaven',
    'genericName': ' Eribulin(er i BUE lin)',
    'tradeName': 'Halaven'
  },
  {
    'drug': 'Halotestin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/halotestin.aspx',
    'title': 'Halotestin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Herceptin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/herceptin.aspx',
    'title': 'Herceptin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Hexadrol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hexadrol.aspx',
    'title': 'Hexadrol',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Hexalen',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hexalen.aspx',
    'title': 'Hexalen',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Hexamethylmelamine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hexamethylmelamine.aspx',
    'title': 'Hexamethylmelamine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Hycamtin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hycamtin.aspx',
    'title': 'Hycamtin',
    'genericName': 'Topotecan',
    'tradeName': null
  },
  {
    'drug': 'Hydrea',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydrea.aspx',
    'title': 'Hydrea',
    'genericName': 'Hydroxyurea , DroxiaTM, MylocelTM',
    'tradeName': 'Hydroxyurea , DroxiaTM, MylocelTM'
  },
  {
    'drug': 'Hydrocort Acetate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydrocort-acetate.aspx',
    'title': 'Hydrocort Acetate',
    'genericName': null,
    'tradeName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,Solu-Cortef , Lanacort , Cortisone,Hydrocortisone Sodium Succinate, Hydrocortisone Sodium Phosphate'
  },
  {
    'drug': 'Hydrocortisone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydrocortisone.aspx',
    'title': 'Hydrocortisone',
    'genericName': null,
    'tradeName': 'Ala-Cort , Hydrocortone Phosphate,Solu-Cortef,Hydrocort Acetate, Lanacort, Cortef, Westcort , Cortisone , Hydrocortisone Sodium Succinate, Hydrocortisone Sodium Phosphate'
  },
  {
    'drug': 'Hydrocortisone Sodium Phosphate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydrocortisone-sodium-phosphate.aspx',
    'title': 'Hydrocortisone Sodium Phosphate',
    'genericName': null,
    'tradeName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,Solu-Cortef , Lanacort , Hydrocort Acetate , Cortisone,Hydrocortisone Sodium Succinate'
  },
  {
    'drug': 'Hydrocortisone Sodium Succinate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydrocortisone-sodium-succinate.aspx',
    'title': 'Hydrocortisone Sodium Succinate',
    'genericName': null,
    'tradeName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,Solu-Cortef , Lanacort , Hydrocort Acetate , Cortisone,Hydrocortisone Sodium Phosphate'
  },
  {
    'drug': 'Hydrocortone Phosphate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydrocortone-phosphate.aspx',
    'title': 'Hydrocortone Phosphate',
    'genericName': null,
    'tradeName': 'Hydrocortisone , Ala-Cort, Solu-Cortef , Lanacort , Hydrocort Acetate , Cortisone,Hydrocortisone Sodium Phosphate, Hydrocortisone Sodium Succinate , Hydrocortone Phosphate'
  },
  {
    'drug': 'Hydroxyurea',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/hydroxyurea.aspx',
    'title': 'Hydroxyurea',
    'genericName': null,
    'tradeName': 'Hydrea, DroxiaTM, MylocelTM'
  },
  {
    'drug': 'Iclusig',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/iclusig.aspx',
    'title': 'ICLUSIG',
    'genericName': 'Ponatinib',
    'tradeName': 'ICLUSIG'
  },
  {
    'drug': 'IL-2',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/il-2.aspx',
    'title': 'IL-2',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'IL-11',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/il-11.aspx',
    'title': 'IL-11',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ibritumomab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ibritumomab.aspx',
    'title': 'Ibritumomab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ibritumomab Tiuxetan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ibritumomabtiuxetan.aspx',
    'title': 'Ibritumomab Tiuxetan',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ibrutinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ibrutinib.aspx',
    'title': 'Ibrutinib',
    'genericName': null,
    'tradeName': 'Imbruvica'
  },
  {
    'drug': 'Idamycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/idamycin.aspx',
    'title': 'Idamycin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Idarubicin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/idarubicin.aspx',
    'title': 'Idarubicin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Idelalisib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/idelalisib.aspx',
    'title': 'Idelalisib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ifex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ifex.aspx',
    'title': 'Ifex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ifosfamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ifosfamide.aspx',
    'title': 'Ifosfamide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Imatinib Mesylate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/imatinib-mesylate.aspx',
    'title': 'Imatinib Mesylate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Imbruvica',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/imbruvica.aspx',
    'title': 'Imbruvica',
    'genericName': 'Ibrutinib',
    'tradeName': null
  },
  {
    'drug': 'Imidazole Carboxamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/imidazole-carboxamide.aspx',
    'title': 'Imidazole Carboxamide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Inlyta',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/inlyta.aspx',
    'title': 'Inlyta',
    'genericName': 'axitinib',
    'tradeName': '  Inlyta'
  },
  {
    'drug': 'Besponsa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/inotuzumab-ozogamicin.aspx',
    'title': 'Inotuzumab Ozogamicin',
    'genericName': null,
    'tradeName': 'Besponsa'
  },
  {
    'drug': 'Alpha Interferon',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/interferon-alfa.aspx',
    'title': 'Interferon Alfa',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Interferon Alfa-2b (PEG Conjugate)',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/interferon-alfa-2b-peg-conjugate.aspx',
    'title': 'Interferon Alfa-2b (PEG Conjugate)',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Interleukin-2',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/interleukin-2.aspx',
    'title': 'Interleukin - 2',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Interleukin-11',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/interleukin-11.aspx',
    'title': 'Interleukin-11',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ipilimumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ipilimumab.aspx',
    'title': 'Ipilimumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Iressa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/iressa.aspx',
    'title': 'Iressa',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Irinotecan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/irinotecan.aspx',
    'title': 'Irinotecan',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Irinotecan (Liposomal)',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/irinotecan-liposomal.aspx',
    'title': 'Irinotecan (Liposomal)',
    'genericName': null,
    'tradeName': 'Onivyde'
  },
  {
    'drug': 'Isotretinoin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/isotretinoin.aspx',
    'title': 'Isotretinoin',
    'genericName': null,
    'tradeName': 'AccutaneAbsorbicaAmnesteemClaravisMyorisanZenatane , 13-cis-Retinoic Acid'
  },
  {
    'drug': 'Istodax',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/istodax.aspx',
    'title': 'Istodax',
    'genericName': 'Romidepsin',
    'tradeName': null
  },
  {
    'drug': 'Ixabepilone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ixabepilone.aspx',
    'title': 'Ixabepilone',
    'genericName': null,
    'tradeName': 'Ixempra'
  },
  {
    'drug': 'Ixazomib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ixazomib.aspx',
    'title': 'Ixazomib',
    'genericName': null,
    'tradeName': 'Ninlaro'
  },
  {
    'drug': 'Ixempra',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ixempra.aspx',
    'title': 'Ixempra',
    'genericName': 'Ixabepilone , Ixempra is the trade name for the generic chemotherapy drug Ixabepilone.In some cases, health care professionals may use the trade name Ixempra when referringto the generic drug name Ixabepilone.',
    'tradeName': null
  },
  {
    'drug': 'Jakafi',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/jakafi.aspx',
    'title': 'Jakafi',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Jevtana',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Jevtana.aspx',
    'title': 'Jevtana',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Kadcyla',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/kadcyla.aspx',
    'title': 'Kadcyla',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Keytruda',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/keytruda.aspx',
    'title': 'Keytruda',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Kidrolase',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Kidrolase.aspx',
    'title': 'Kidrolase',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Kyprolis',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/kyprolis.aspx',
    'title': 'Kyprolis',
    'genericName': 'Carfilzomib',
    'tradeName': null
  },
  {
    'drug': 'L-PAM',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/LPAM.aspx',
    'title': 'L-PAM',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'L-Sarcolysin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/LSarcolysin.aspx',
    'title': 'L-Sarcolysin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'L-Asparaginase',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Lasparaginase.aspx',
    'title': 'L-asparaginase',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Lcr',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/lcr.aspx',
    'title': 'LCR',
    'genericName': 'Vincristine , Oncovin, Vincasar Pfs , Vincristine Sulfate. Originally known as Leurocristine, it has also been referred to as LCR and VCR.',
    'tradeName': 'Vincristine , Oncovin, Vincasar Pfs , Vincristine Sulfate. Originally known as Leurocristine, it has also been referred to as LCR and VCR.'
  },
  {
    'drug': 'Lanacort',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Lanacort.aspx',
    'title': 'Lanacort',
    'genericName': null,
    'tradeName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,Solu-Cortef , Hydrocort Acetate  , Cortisone,Hydrocortisone Sodium Succinate, Hydrocortisone Sodium Phosphate'
  },
  {
    'drug': 'Lanreotide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/lanreotide.aspx',
    'title': 'Lanreotide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Lapatinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/lapitinib.aspx',
    'title': 'Lapatinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Lenalidomide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Lenalidomide.aspx',
    'title': 'Lenalidomide',
    'genericName': null,
    'tradeName': 'Revlimid'
  },
  {
    'drug': 'Lenvatinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/lenvatinib.aspx',
    'title': 'Lenvatinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Letrozole',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Letrozole.aspx',
    'title': 'Letrozole',
    'genericName': null,
    'tradeName': 'Femara'
  },
  {
    'drug': 'Leucovorin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Leucovorin.aspx',
    'title': 'Leucovorin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Leukeran',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Leukeran.aspx',
    'title': 'Leukeran',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Leukine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Leukine.aspx',
    'title': 'Leukine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Leuprolide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Leuprolide.aspx',
    'title': 'Leuprolide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Leurocristine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Leurocristine.aspx',
    'title': 'Leurocristine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Liposomal Ara-C',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Liposomal-AraC.aspx',
    'title': 'Liposomal Ara-C',
    'genericName': 'Cytarabine (Liposomal)',
    'tradeName': null
  },
  {
    'drug': 'Liquid Pred',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Liquid-Pred.aspx',
    'title': 'Liquid Pred',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Lomustine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Lomustine.aspx',
    'title': 'Lomustine',
    'genericName': null,
    'tradeName': 'Gleostine'
  },
  {
    'drug': 'Lonsurf',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/lonsurf.aspx',
    'title': 'Lonsurf',
    'genericName': 'Trifluridine/Tipiricil',
    'tradeName': null
  },
  {
    'drug': 'Lupron',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Lupron.aspx',
    'title': 'Lupron',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Lupron Depot',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Lupron-Depot.aspx',
    'title': 'Lupron Depot',
    'genericName': 'Leuprolide , Eligard TM, Lupron , Viadur TM',
    'tradeName': 'Leuprolide , Eligard TM, Lupron , Viadur TM'
  },
  {
    'drug': 'Lynparza',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/lynparza.aspx',
    'title': 'Lynparza',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'M-Prednisol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/m-prednisol.aspx',
    'title': 'M-Prednisol',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'MTC',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/MTC.aspx',
    'title': 'MTC',
    'genericName': 'Mitomycin',
    'tradeName': null
  },
  {
    'drug': 'Marqibo',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/marqibo.aspx',
    'title': 'Marqibo',
    'genericName': 'Vincristine Liposomal',
    'tradeName': null
  },
  {
    'drug': 'Matulane',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Matulane.aspx',
    'title': 'Matulane',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Maxidex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Maxidex.aspx',
    'title': 'Maxidex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mechlorethamine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mechlorethamine.aspx',
    'title': 'Mechlorethamine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mechlorethamine Hydrochloride',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mechlorethamine-Hydrochloride.aspx',
    'title': 'Mechlorethamine Hydrochloride',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Medralone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Medralone.aspx',
    'title': 'Medralone',
    'genericName': 'Methylprednisolone',
    'tradeName': null
  },
  {
    'drug': 'Medrol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Medrol.aspx',
    'title': 'Medrol',
    'genericName': 'Methylprednisolone , Duralone, Medralone, M-Prednisol, Solu-Medrol , 6-Methylprednisolone, Methylprednisolone Acetate,Methylprednisolone Sodium Succinate',
    'tradeName': 'Methylprednisolone , Duralone, Medralone, M-Prednisol, Solu-Medrol , 6-Methylprednisolone, Methylprednisolone Acetate,Methylprednisolone Sodium Succinate'
  },
  {
    'drug': 'Megace',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Megace.aspx',
    'title': 'Megace',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Megestrol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Megestrol.aspx',
    'title': 'Megestrol',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Megestrol Acetate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Megestrol-Acetate.aspx',
    'title': 'Megestrol Acetate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mekinist',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/mekinist.aspx',
    'title': 'Mekinist',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Melphalan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Melphalan.aspx',
    'title': 'Melphalan',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mercaptopurine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mercaptopurine.aspx',
    'title': 'Mercaptopurine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mesna',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mesna.aspx',
    'title': 'Mesna',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mesnex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mesnex.aspx',
    'title': 'Mesnex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Amethopterin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/methotrexate.aspx',
    'title': 'Methotrexate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Methylprednisolone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Methylprednisolone.aspx',
    'title': 'Methylprednisolone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Meticorten',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Meticorten.aspx',
    'title': 'Meticorten',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Midostaurin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/midostaurin.aspx',
    'title': 'Midostaurin',
    'genericName': null,
    'tradeName': 'Rydapt'
  },
  {
    'drug': 'Mitomycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mitomycin.aspx',
    'title': 'Mitomycin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mitomycin-C',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/MitomycinC.aspx',
    'title': 'Mitomycin-C',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mitoxantrone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mitoxantrone.aspx',
    'title': 'Mitoxantrone',
    'genericName': null,
    'tradeName': 'Novantrone  , DHAD'
  },
  {
    'drug': 'Mustargen',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mustargen.aspx',
    'title': 'Mustargen',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mustine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mustine.aspx',
    'title': 'Mustine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mutamycin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mutamycin.aspx',
    'title': 'Mutamycin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Myleran',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Myleran.aspx',
    'title': 'Myleran',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mylocel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mylocel.aspx',
    'title': 'Mylocel',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Mylotarg',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Mylotarg.aspx',
    'title': 'Mylotarg',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Navelbine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Navelbine.aspx',
    'title': 'Navelbine',
    'genericName': 'Vinorelbine , Vinorelbine Tartrate',
    'tradeName': null
  },
  {
    'drug': 'Necitumumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/necitumumab.aspx',
    'title': 'Necitumumab',
    'genericName': null,
    'tradeName': 'Portrazza'
  },
  {
    'drug': 'Nelarabine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nelarabine.aspx',
    'title': 'Nelarabine',
    'genericName': null,
    'tradeName': 'Arranon'
  },
  {
    'drug': 'Neosar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Neosar.aspx',
    'title': 'Neosar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Neratinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/neratinib.aspx',
    'title': 'Neratinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Neulasta',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Neulasta.aspx',
    'title': 'Neulasta',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Neumega',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Neumega.aspx',
    'title': 'Neumega',
    'genericName': 'Oprelvekin',
    'tradeName': null
  },
  {
    'drug': 'Neupogen',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Neupogen.aspx',
    'title': 'Neupogen',
    'genericName': 'Filgrastim',
    'tradeName': 'Neupogen, Granix, Zarxio'
  },
  {
    'drug': 'Nexavar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nexavar.aspx',
    'title': 'Nexavar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Nilandron',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nilandron.aspx',
    'title': 'Nilandron',
    'genericName': 'Nilutamide',
    'tradeName': 'Nilandron, Anandron'
  },
  {
    'drug': 'Nilotinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nilotinib.aspx',
    'title': 'Nilotinib',
    'genericName': 'nilotinib , Tasigna',
    'tradeName': 'nilotinib , Tasigna'
  },
  {
    'drug': 'Nilutamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nilutamide.aspx',
    'title': 'Nilutamide',
    'genericName': null,
    'tradeName': 'Nilandron, Anandron'
  },
  {
    'drug': 'Nipent',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nipent.aspx',
    'title': 'Nipent',
    'genericName': 'Pentostatin',
    'tradeName': null
  },
  {
    'drug': 'Niraparib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/niraparib.aspx',
    'title': 'Niraparib',
    'genericName': null,
    'tradeName': 'ZejulaTM'
  },
  {
    'drug': 'Nitrogen Mustard',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nitrogen-Mustard.aspx',
    'title': 'Nitrogen Mustard',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Nivolumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nivolumab.aspx',
    'title': 'Nivolumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Nolvadex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nolvadex.aspx',
    'title': 'Nolvadex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Novantrone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Novantrone.aspx',
    'title': 'Novantrone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Nplate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Nplate.aspx',
    'title': 'Nplate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Obinutuzumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/obinutuzumab.aspx',
    'title': 'Obinutuzumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Octreotide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Octreotide.aspx',
    'title': 'Octreotide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Octreotide Acetate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Octreotide-acetate.aspx',
    'title': 'Octreotide Acetate',
    'genericName': 'Octreotide',
    'tradeName': null
  },
  {
    'drug': 'Ofatumumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Ofatumumab.aspx',
    'title': 'Ofatumumab',
    'genericName': null,
    'tradeName': ' Arzerra'
  },
  {
    'drug': 'Olaparib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Olaparib.aspx',
    'title': 'Olaparib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Lartruvo',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/olaratumab.aspx',
    'title': 'Olaratumab',
    'genericName': null,
    'tradeName': 'Lartruvo'
  },
  {
    'drug': 'Omacetaxine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/omacetaxine.aspx',
    'title': 'Omacetaxine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Oncospar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Oncospar.aspx',
    'title': 'Oncospar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Oncovin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Oncovin.aspx',
    'title': 'Oncovin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ontak',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Ontak.aspx',
    'title': 'Ontak',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Onxal',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Onxal.aspx',
    'title': 'Onxal',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Opdivo',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/opdivo.aspx',
    'title': 'Opdivo',
    'genericName': 'Nivolumab',
    'tradeName': null
  },
  {
    'drug': 'Oprelvekin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Oprelvekin.aspx',
    'title': 'Oprelvekin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Orapred',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Orapred.aspx',
    'title': 'Orapred',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Orasone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Orasone.aspx',
    'title': 'Orasone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Osimertinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/osimertinib.aspx',
    'title': 'Osimertinib',
    'genericName': null,
    'tradeName': 'Tagrisso'
  },
  {
    'drug': 'Oxaliplatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Oxaliplatin.aspx',
    'title': 'Oxaliplatin',
    'genericName': null,
    'tradeName': 'EloxatinTM'
  },
  {
    'drug': 'Peg Interferon',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/PEG-Interferon.aspx',
    'title': 'PEG Interferon',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Peg-Intron',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/PEGINTRON.aspx',
    'title': 'PEG-INTRON',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'PEG-L-asparaginase',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/PEGLasparaginase.aspx',
    'title': 'PEG-L-asparaginase',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Procrit',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/PROCRIT.aspx',
    'title': 'PROCRIT',
    'genericName': 'Epoetin Alfa',
    'tradeName': 'PROCRIT'
  },
  {
    'drug': 'Paclitaxel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Paclitaxel.aspx',
    'title': 'Paclitaxel',
    'genericName': null,
    'tradeName': 'Taxol, OnxalTM'
  },
  {
    'drug': 'Paclitaxel Protein-bound',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Paclitaxel-Proteinbound.aspx',
    'title': 'Paclitaxel Protein-Bound',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ibrance',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/palbociclib.aspx',
    'title': 'Palbociclib',
    'genericName': null,
    'tradeName': 'Ibrance'
  },
  {
    'drug': 'Pamidronate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pamidronate.aspx',
    'title': 'Pamidronate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Panitumumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Panitumumab.aspx',
    'title': 'Panitumumab',
    'genericName': null,
    'tradeName': ' Vectibix'
  },
  {
    'drug': 'Panobinostat',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/panobinostat.aspx',
    'title': 'Panobinostat',
    'genericName': null,
    'tradeName': 'Farydak'
  },
  {
    'drug': 'Panretin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Panretin.aspx',
    'title': 'Panretin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Paraplatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Paraplatin.aspx',
    'title': 'Paraplatin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Pazopanib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pazopanib.aspx',
    'title': 'Pazopanib',
    'genericName': 'Pazopanib , Votrient',
    'tradeName': 'Pazopanib , Votrient'
  },
  {
    'drug': 'Pediapred',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pediapred.aspx',
    'title': 'Pediapred',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Pegaspargase',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pegaspargase.aspx',
    'title': 'Pegaspargase',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Pegfilgrastim',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pegfilgrastim.aspx',
    'title': 'Pegfilgrastim',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Pembrolizumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pembrolizumab.aspx',
    'title': 'Pembrolizumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Pemetrexed',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/PEMETREXED.aspx',
    'title': 'Pemetrexed',
    'genericName': null,
    'tradeName': 'Alimta'
  },
  {
    'drug': 'Pentostatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pentostatin.aspx',
    'title': 'Pentostatin',
    'genericName': null,
    'tradeName': 'Nipent'
  },
  {
    'drug': 'Perjeta',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Perjeta.aspx',
    'title': 'Perjeta',
    'genericName': '',
    'tradeName': null
  },
  {
    'drug': 'Pertuzumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Pertuzumab.aspx',
    'title': 'Pertuzumab',
    'genericName': null,
    'tradeName': 'Perjeta'
  },
  {
    'drug': 'Phenylalanine Mustard',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Phenylalanine-Mustard.aspx',
    'title': 'Phenylalanine Mustard',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Platinol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Platinol.aspx',
    'title': 'Platinol',
    'genericName': null,
    'tradeName': 'Platinol, Platinol-AQ'
  },
  {
    'drug': 'Platinol-AQ',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/PlatinolAQ.aspx',
    'title': 'Platinol-AQ',
    'genericName': null,
    'tradeName': 'Platinol, Platinol-AQ'
  },
  {
    'drug': 'Pomalidomide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/pomalidomide.aspx',
    'title': 'Pomalidomide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Pomalyst',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/pomalyst.aspx',
    'title': 'Pomalyst',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Ponatinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ponatinib.aspx',
    'title': 'Ponatinib',
    'genericName': 'Ponatinib',
    'tradeName': 'ICLUSIG'
  },
  {
    'drug': 'Pralatrexate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/pralatrexate.aspx',
    'title': 'Pralatrexate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Prednisolone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Prednisolone.aspx',
    'title': 'Prednisolone',
    'genericName': null,
    'tradeName': '  Delta-Cortef , Orapred , Pediapred , Prelone'
  },
  {
    'drug': 'Prednisone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Prednisone.aspx',
    'title': 'Prednisone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Prelone',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Prelone.aspx',
    'title': 'Prelone',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Procarbazine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Procarbazine.aspx',
    'title': 'Procarbazine',
    'genericName': null,
    'tradeName': ' Matulane '
  },
  {
    'drug': 'Proleukin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Proleukin.aspx',
    'title': 'Proleukin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Prolia',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Prolia.aspx',
    'title': 'Prolia',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Prolifeprospan 20 with Carmustine Implant',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/prolifeprospan-with-carmustine.aspx',
    'title': 'Prolifeprospan With Carmustine',
    'genericName': 'Carmustine Wafer',
    'tradeName': 'Gliadel wafer'
  },
  {
    'drug': 'Provenge',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Provenge.aspx',
    'title': 'Provenge',
    'genericName': ' Sipuleucel-T',
    'tradeName': null
  },
  {
    'drug': 'Purinethol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Purinethol.aspx',
    'title': 'Purinethol',
    'genericName': 'Mercaptopurine , 6-Mercaptopurine,6-MP',
    'tradeName': null
  },
  {
    'drug': 'Radium 223 Dichloride',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/radium-223-dichloride.aspx',
    'title': 'Radium 223 Dichloride',
    'genericName': 'Radium 223 Dichloride',
    'tradeName': 'Xofigo'
  },
  {
    'drug': 'Raloxifene',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Raloxifene.aspx',
    'title': 'Raloxifene',
    'genericName': null,
    'tradeName': '  Evista'
  },
  {
    'drug': 'Ramucirumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ramucirumab.aspx',
    'title': 'Ramucirumab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Regorafenib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/regorafenib.aspx',
    'title': 'Regorafenib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Revlimid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Revlimid.aspx',
    'title': 'Revlimid',
    'genericName': 'Lenalidomide',
    'tradeName': null
  },
  {
    'drug': 'Kisqali',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ribociclib.aspx',
    'title': 'Ribociclib',
    'genericName': null,
    'tradeName': 'Kisqali'
  },
  {
    'drug': 'Rituxan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Rituxan.aspx',
    'title': 'Rituxan',
    'genericName': 'Rituximab',
    'tradeName': null
  },
  {
    'drug': 'Rituximab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Rituximab.aspx',
    'title': 'Rituximab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Rituxan Hycela',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/rituximab-hyalurodinase.aspx',
    'title': 'Rituximab Hyaluronidase',
    'genericName': null,
    'tradeName': 'Rituxan HycelaTM'
  },
  {
    'drug': 'Romidepsin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/romidepsin.aspx',
    'title': 'Romidepsin',
    'genericName': null,
    'tradeName': 'Istodax'
  },
  {
    'drug': 'Romiplostim',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Romiplostim.aspx',
    'title': 'Romiplostim',
    'genericName': null,
    'tradeName': 'NplateTM'
  },
  {
    'drug': 'Rubidomycin Hydrochloride',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Rubidomycin-hydrochloride.aspx',
    'title': 'Rubidomycin Hydrochloride',
    'genericName': 'Daunorubicin',
    'tradeName': 'Cerubidine'
  },
  {
    'drug': 'Rubraca',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/rucaparib.aspx',
    'title': 'Rucaparib',
    'genericName': null,
    'tradeName': 'Rubraca'
  },
  {
    'drug': 'Ruxolitinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ruxolitinib.aspx',
    'title': 'Ruxolitinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sprycel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/SPRYCEL.aspx',
    'title': 'SPRYCEL',
    'genericName': 'Dasatinib',
    'tradeName': null
  },
  {
    'drug': 'Sti-571',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/STI-571.aspx',
    'title': 'STI-571',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'SU11248',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/SU11248.aspx',
    'title': 'SU11248',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sandostatin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Sandostatin.aspx',
    'title': 'Sandostatin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sandostatin LAR',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Sandostatin-LAR.aspx',
    'title': 'Sandostatin LAR',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sargramostim',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Sargramostim.aspx',
    'title': 'Sargramostim',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Siltuximab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/siltuximab.aspx',
    'title': 'Siltuximab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sipuleucel-T',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/SipuleucelT.aspx',
    'title': 'Sipuleucel-T',
    'genericName': null,
    'tradeName': ' Provenge'
  },
  {
    'drug': 'Soliris',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Soliris.aspx',
    'title': 'Soliris',
    'genericName': 'Eculizumab',
    'tradeName': 'Soliris'
  },
  {
    'drug': 'Solu-Cortef',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/SoluCortef.aspx',
    'title': 'Solu-Cortef',
    'genericName': null,
    'tradeName': 'Hydrocortisone , Ala-Cort, Hydrocortone Phosphate,Hydrocort Acetate , Lanacort , Cortisone,Hydrocortisone Sodium Succinate, Hydrocortisone Sodium Phosphate'
  },
  {
    'drug': 'Solu-Medrol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/solu-medrol.aspx',
    'title': 'Solu-Medrol',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Somatuline',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/somatuline.aspx',
    'title': 'Somatuline Depot Injection',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Odomzo',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/sonidegib.aspx',
    'title': 'Sonidegib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sorafenib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Sorafenib.aspx',
    'title': 'Sorafenib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Stivarga',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/stivarga.aspx',
    'title': 'Stivarga',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Streptozocin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Streptozocin.aspx',
    'title': 'Streptozocin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Sunitinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Sunitinib.aspx',
    'title': 'Sunitinib',
    'genericName': null,
    'tradeName': 'Sutent , SU11248'
  },
  {
    'drug': 'Sutent',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Sutent.aspx',
    'title': 'Sutent',
    'genericName': 'Sunitinib , SU11248',
    'tradeName': null
  },
  {
    'drug': 'Sylvant',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/sylvant.aspx',
    'title': 'Sylvant',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Synribo',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/synribo.aspx',
    'title': 'Synribo',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tespa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/TESPA.aspx',
    'title': 'TESPA',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tice',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/TICE.aspx',
    'title': 'TICE',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tspa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/TSPA.aspx',
    'title': 'TSPA',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tykerb',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/TYKERB.aspx',
    'title': 'TYKERB',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tafinlar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/tafinlar.aspx',
    'title': 'Tafinlar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Imlygic',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/talimogene-laherparepvec.aspx',
    'title': 'Talimogene Laherparepvec',
    'genericName': null,
    'tradeName': 'Imlygic'
  },
  {
    'drug': 'Tamoxifen',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Tamoxifen.aspx',
    'title': 'Tamoxifen',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tarceva',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Tarceva.aspx',
    'title': 'Tarceva',
    'genericName': 'Erlotinib',
    'tradeName': null
  },
  {
    'drug': 'Targretin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Targretin.aspx',
    'title': 'Targretin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Tasigna',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Tasigna.aspx',
    'title': 'Tasigna',
    'genericName': 'nilotinib',
    'tradeName': null
  },
  {
    'drug': 'Taxol',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Taxol.aspx',
    'title': 'Taxol',
    'genericName': 'Paclitaxel , Onxal TM',
    'tradeName': 'Paclitaxel , Onxal TM'
  },
  {
    'drug': 'Taxotere',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Taxotere.aspx',
    'title': 'Taxotere',
    'genericName': 'Docetaxel',
    'tradeName': null
  },
  {
    'drug': 'Temodar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Temodar.aspx',
    'title': 'Temodar',
    'genericName': 'Temozolomide',
    'tradeName': null
  },
  {
    'drug': 'Temozolomide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Temozolomide.aspx',
    'title': 'Temozolomide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Temsirolimus',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Temsirolimus.aspx',
    'title': 'Temsirolimus',
    'genericName': null,
    'tradeName': ' Torisel ,CCI-779'
  },
  {
    'drug': 'Teniposide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Teniposide.aspx',
    'title': 'Teniposide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Thalidomide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thalidomide.aspx',
    'title': 'Thalidomide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Thalomid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thalomid.aspx',
    'title': 'Thalomid',
    'genericName': '  Thalidomide',
    'tradeName': null
  },
  {
    'drug': 'TheraCys',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/TheraCys.aspx',
    'title': 'TheraCys',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Thioguanine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thioguanine.aspx',
    'title': 'Thioguanine',
    'genericName': null,
    'tradeName': 'Tabloid , 6-TG, 6-Thioguanine, 2-Amino-6-Mercaptopurine'
  },
  {
    'drug': 'Thioguanine Tabloid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thioguanine-Tabloid.aspx',
    'title': 'Thioguanine Tabloid',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Thiophosphoamide',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thiophosphoamide.aspx',
    'title': 'Thiophosphoamide',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Thioplex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thioplex.aspx',
    'title': 'Thioplex',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Thiotepa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Thiotepa.aspx',
    'title': 'Thiotepa',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Kymriah',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/tisagenlecleucel.aspx',
    'title': 'Tisagenlecleucel',
    'genericName': null,
    'tradeName': 'Kymriah'
  },
  {
    'drug': 'Toposar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Toposar.aspx',
    'title': 'Toposar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Topotecan',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Topotecan.aspx',
    'title': 'Topotecan',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Toremifene',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Toremifene.aspx',
    'title': 'Toremifene',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Torisel',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Torisel.aspx',
    'title': 'Torisel',
    'genericName': ' Temsirolimus ,   CCI-779',
    'tradeName': null
  },
  {
    'drug': 'Tositumomab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Tositumomab.aspx',
    'title': 'Tositumomab',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Trabectedin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/trabectedin.aspx',
    'title': 'Trabectedin',
    'genericName': null,
    'tradeName': 'Yondelis'
  },
  {
    'drug': 'Trametinib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/trametinib.aspx',
    'title': 'Trametinib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Trastuzumab',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Trastuzumab.aspx',
    'title': 'Trastuzumab',
    'genericName': null,
    'tradeName': 'Herceptin'
  },
  {
    'drug': 'Treanda',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Treanda.aspx',
    'title': 'Treanda',
    'genericName': 'Bendamustine hydrochloride , Treanda',
    'tradeName': 'Bendamustine hydrochloride , Treanda'
  },
  {
    'drug': 'Trelstar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/trelstar.aspx',
    'title': 'Trelstar',
    'genericName': 'Triptorelin Pamoate',
    'tradeName': null
  },
  {
    'drug': 'Tretinoin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Tretinoin.aspx',
    'title': 'Tretinoin',
    'genericName': null,
    'tradeName': 'Vesanoid'
  },
  {
    'drug': 'Trifluridine/Tipiricil',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/trifluridine-tipiricil.aspx',
    'title': 'Trifluridine/Tipiricil',
    'genericName': null,
    'tradeName': 'Lonsurf'
  },
  {
    'drug': 'Triptorelin pamoate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/triptorelin-pamoate.aspx',
    'title': 'Triptorelin Pamoate',
    'genericName': null,
    'tradeName': 'U.S. Trelstar Trelstar Depot; Trelstar Depot Mixject; Trelstar LA; Trelstar LA Mixject; Trelstar Mixject'
  },
  {
    'drug': 'VCR',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/vcr.aspx',
    'title': 'VCR',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vlb',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/VLB.aspx',
    'title': 'VLB',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'VM-26',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/VM-26.aspx',
    'title': 'VM-26',
    'genericName': 'Teniposide',
    'tradeName': null
  },
  {
    'drug': 'VP-16',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/VP-16.aspx',
    'title': 'VP-16',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Valrubicin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/valrubicin.aspx',
    'title': 'Valrubicin',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Valstar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/valstar.aspx',
    'title': 'Valstar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vandetanib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/vandetanib.aspx',
    'title': 'Vandetanib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'VePesid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/VePesid.aspx',
    'title': 'VePesid',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vectibix',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vectibix.aspx',
    'title': 'Vectibix',
    'genericName': ' Panitumumab',
    'tradeName': null
  },
  {
    'drug': 'Velban',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Velban.aspx',
    'title': 'Velban',
    'genericName': 'Vinblastine',
    'tradeName': null
  },
  {
    'drug': 'Velcade',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Velcade.aspx',
    'title': 'Velcade',
    'genericName': 'Bortezomib',
    'tradeName': null
  },
  {
    'drug': 'Vemurafenib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/vemurafenib.aspx',
    'title': 'Vemurafenib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Venclexta',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/venetoclax.aspx',
    'title': 'Venetoclax',
    'genericName': null,
    'tradeName': 'Venclexta'
  },
  {
    'drug': 'Vesanoid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vesanoid.aspx',
    'title': 'Vesanoid',
    'genericName': 'Tretinoin',
    'tradeName': 'Vesanoid'
  },
  {
    'drug': 'Viadur',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Viadur.aspx',
    'title': 'Viadur',
    'genericName': 'Leuprolide',
    'tradeName': null
  },
  {
    'drug': 'Vinblastine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vinblastine.aspx',
    'title': 'Vinblastine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vinblastine Sulfate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vinblastine-Sulfate.aspx',
    'title': 'Vinblastine Sulfate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vincasar Pfs',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vincasar-Pfs.aspx',
    'title': 'Vincasar Pfs',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vincristine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vincristine.aspx',
    'title': 'Vincristine',
    'genericName': null,
    'tradeName': 'Oncovin, Vincasar Pfs , Vincristine Sulfate, LCR, VCR'
  },
  {
    'drug': 'Vincristine Liposomal',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/vincristine-liposomal.aspx',
    'title': 'Vincristine Liposomal',
    'genericName': null,
    'tradeName': 'Marqibo'
  },
  {
    'drug': 'Vinorelbine',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vinorelbine.aspx',
    'title': 'Vinorelbine',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vinorelbine Tartrate',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vinorelbine-tartrate.aspx',
    'title': 'Vinorelbine tartrate',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vismodegib',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vismodegib.aspx',
    'title': 'Vismodegib',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Vorinostat',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vorinostat.aspx',
    'title': 'Vorinostat',
    'genericName': null,
    'tradeName': ' Zolinza'
  },
  {
    'drug': 'Votrient',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Votrient.aspx',
    'title': 'Votrient',
    'genericName': 'Pazopanib , Votrient',
    'tradeName': 'Pazopanib , Votrient'
  },
  {
    'drug': 'Vumon',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Vumon.aspx',
    'title': 'Vumon',
    'genericName': 'Teniposide',
    'tradeName': null
  },
  {
    'drug': 'Xalkori Capsules',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Xalkori-capsules.aspx',
    'title': 'Xalkori Capsules',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Xeloda',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Xeloda.aspx',
    'title': 'Xeloda',
    'genericName': 'Capecitabine',
    'tradeName': null
  },
  {
    'drug': 'Xgeva',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Xgeva.aspx',
    'title': 'Xgeva',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Xofigo',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/xofigo.aspx',
    'title': 'Xofigo',
    'genericName': 'Radium 223 Dichloride',
    'tradeName': 'Xofigo'
  },
  {
    'drug': 'Xtandi',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/xtandi.aspx',
    'title': 'Xtandi',
    'genericName': 'Enzalutamide',
    'tradeName': null
  },
  {
    'drug': 'Yervoy',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Yervoy.aspx',
    'title': 'Yervoy',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Zaltrap',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/zaltrap.aspx',
    'title': 'Zaltrap',
    'genericName': 'Ziv-aflibercept',
    'tradeName': 'Zaltrap'
  },
  {
    'drug': 'Zanosar',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zanosar.aspx',
    'title': 'Zanosar',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Zelboraf',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/zelboraf.aspx',
    'title': 'Zelboraf',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Zevalin',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zevalin.aspx',
    'title': 'Zevalin',
    'genericName': 'Ibritumomab , Ibritumomab Tiuxetan',
    'tradeName': null
  },
  {
    'drug': 'Zinecard',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zinecard.aspx',
    'title': 'Zinecard',
    'genericName': 'Dexrazoxane',
    'tradeName': null
  },
  {
    'drug': 'Ziv-aflibercept',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/ziv-aflibercept.aspx',
    'title': 'Ziv-aflibercept',
    'genericName': null,
    'tradeName': 'Zaltrap'
  },
  {
    'drug': 'Zoladex',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zoladex.aspx',
    'title': 'Zoladex',
    'genericName': 'Goserelin',
    'tradeName': null
  },
  {
    'drug': 'Zoledronic Acid',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zoledronic-Acid.aspx',
    'title': 'Zoledronic Acid',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Zolinza',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zolinza.aspx',
    'title': 'Zolinza',
    'genericName': ' Vorinostat',
    'tradeName': null
  },
  {
    'drug': 'Zometa',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/Zometa.aspx',
    'title': 'Zometa',
    'genericName': 'Zoledronic Acid',
    'tradeName': null
  },
  {
    'drug': 'Zydelig',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/zydelig.aspx',
    'title': 'Zydelig',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Zykadia',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/zykadia.aspx',
    'title': 'Zykadia',
    'genericName': null,
    'tradeName': null
  },
  {
    'drug': 'Zytiga',
    'drug-href': 'http://www.chemocare.com/chemotherapy/drug-info/zytiga.aspx',
    'title': 'Zytiga',
    'genericName': null,
    'tradeName': null
  }
];
