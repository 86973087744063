import {Points} from './points';
import {GameSettings} from './gamesettings';
import {deserialize, deserializeAs, serialize, serializeAs} from 'cerialize';

export class GameType {

  static uniqueName = 'GameType';
  nameInstance = GameType.uniqueName;

  @serializeAs(Points)
  @deserializeAs(Points)
  points: Points = new Points();

  @serializeAs(GameSettings)
  @deserializeAs(GameSettings)
  gameSettings: GameSettings = new GameSettings();

  @serialize
  @deserialize
  name: string = null;

  @serialize
  @deserialize
  id = -1;

}
