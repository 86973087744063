import {Injectable} from '@angular/core';
import {DrugInput} from '../model/drug-input';

@Injectable({
  providedIn: 'root'
})
export class StaticContextService {

  drugInput: DrugInput = new DrugInput();

  constructor() {
  }

  objectToArray(object: any) {
    if (!object || object.constructor !== {}.constructor) {
      return null;
    }
    const array = [];
    Object.keys(object).forEach(value => {
      array.push({
        key: value,
        value: object[value]
      });
    });
    return array;
  }
}
