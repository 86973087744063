import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from '@angular/router';
import {Observable} from 'rxjs/Observable';
import {Team} from '../models/team';
import {Game} from '../models/game';
import {UsersService} from './users.service';
import {GameType} from '../models/gametype';
import {GameTypesService} from './game-types.service';

@Injectable()
export class GameService implements Resolve<any> {

  game: Game;

  constructor(public userService: UsersService, public gameTypesService: GameTypesService) {
    if (!this.game) {
      this.newGame(this.userService.lastTeams[0], this.userService.lastTeams[1]);
    }
  }

  pastGames(): Game[] {

    let attempt = +localStorage.getItem('latestGameNum');

    attempt = isNaN(attempt) ? 0 : attempt + 1;

    const game: Game[] = [];

    for (let i = 0; i <= attempt; i++) {
      try {
        const gameItem = localStorage.getObject(`PastGame_${i}`, Game);
        if (gameItem) {
          game.push(gameItem);
        }
      } catch (e) {

      }
    }

    return game;

  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> | Promise<any> | any {
    return new Promise((resolve, reject) => {
      Promise.all([
        this.game
      ]).then(
        ([events]: [any]) => {
          resolve();
        },
        reject
      );
    });
  }

  newGame(teamOne: Team = this.userService.lastTeams[0], teamTwo: Team = this.userService.lastTeams[1], gameType: GameType = this.gameTypesService.currentGameType) {
    this.game = new Game(teamOne, teamTwo, gameType);
  }

  private saveGame() {
    if (!this.game) {
      return;
    }

    this.game.saveGame();
  }

}
